import { Link } from 'react-router-dom';
import { Alert, Col, Row, Typography } from 'antd';
import { useProductsQuery } from '../apollo/api';
import Bookcase from '../ui-components/Bookcase';
import BookCover from '../ui-components/BookCover';

export default function Library() {
  const { loading, error, data } = useProductsQuery();

  return (
    <>
      <h1>Libreria</h1>

      {loading && <p>Caricamento...</p>}

      {error && <Alert message={error.message} type="error" showIcon />}

      {data && (
        <Bookcase>
          <Row justify="space-around">
            {data.productInstances.map((p) => {
              const product = p.product!;
              return (
                <Link to={(location) => `${location.pathname}/${product.id}`} key={product.id}>
                  <Col className="Library-item">
                    <BookCover
                      url={product.img_url}
                      wrapperClassName="Library-item-cover-container"
                      className="Library-item-cover"
                    />
                    <Typography.Paragraph ellipsis={{ rows: 3 }} className="Library-item-title">
                      <span dangerouslySetInnerHTML={{ __html: product.description ?? '' }} />
                    </Typography.Paragraph>
                  </Col>
                </Link>
              );
            })}
          </Row>
        </Bookcase>
      )}
    </>
  );
}
