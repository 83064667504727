import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

const AUTH_TOKEN = 'AuthToken';

export function useAuth() {
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN]);
  const token: string | undefined = cookies[AUTH_TOKEN];

  const setToken = useCallback(
    (token: string) => {
      setCookie(AUTH_TOKEN, token, { path: '/', secure: true, maxAge: 365 * 24 * 60 * 60 });
    },
    [setCookie]
  );

  const removeToken = useCallback(() => {
    removeCookie(AUTH_TOKEN, { path: '/' });
  }, [removeCookie]);

  return { auth: !!token, token, setToken, removeToken };
}
