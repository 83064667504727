import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Modal, Row, Table, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnType } from 'antd/lib/table';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PureQueryOptions } from '@apollo/client';
import {
  BOProductFragment,
  BOUserProductsDocument,
  ProductDocument,
  ProductsDocument,
  useAddBOUserProductMutation,
  useBOProductsQuery,
  useBOUserProductsQuery,
  useBOUserQuery,
  useRemoveBOUserProductMutation,
} from '../../apollo/api';
import BackButton from '../../ui-components/BackButton';

export default function User(p: { authUserId: string }) {
  const { userId } = useParams<{ userId: string }>();

  const { loading: userLoading, error: userError, data: userData } = useBOUserQuery({ variables: { id: userId } });

  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useBOUserProductsQuery({ variables: { userId } });

  const user = userData?.user;
  const products = productsData?.productInstances.map((p) => p.product!);

  const [productAdditionModalVisible, setProductAdditionModalVisible] = useState(false);

  const columns: ColumnType<BOProductFragment>[] = [
    {
      title: 'Prodotto',
      key: 'title',
      render: (_, p) => (
        <strong>
          {p.id}: <span dangerouslySetInnerHTML={{ __html: p.description ?? '' }} />
        </strong>
      ),
    },
    {
      title: (
        <>
          <Button onClick={() => setProductAdditionModalVisible(true)}>Aggiungi...</Button>
          <ProductAdditionModal
            authUserId={p.authUserId}
            userId={userId}
            visible={productAdditionModalVisible}
            closeFn={() => setProductAdditionModalVisible(false)}
          />
        </>
      ),
      align: 'center',
      width: 140,
      key: 'removal',
      render: (_, prod) => <ProductRemoval authUserId={p.authUserId} userId={userId} product={prod} />,
    },
  ];

  return (
    <>
      {(userLoading || productsLoading) && <p>Caricamento...</p>}

      {(userError || productsError) && <Alert message={(userError ?? productsError)!.message} type="error" showIcon />}

      {user && products && (
        <>
          <div className="Header">
            <BackButton className="Action-back" />
            <h1>
              {user.first_name ?? ''}
              {user.first_name && user.last_name ? ' ' : ''}
              {user.last_name ?? ''}
              {(user.first_name || user.last_name) && user.email ? ' - ' : ''}
              {user.email ?? ''}
            </h1>
          </div>

          <div className="BackOffice">
            <div className="Content">
              <Table<BOProductFragment>
                columns={columns}
                dataSource={products}
                rowKey={(p) => p.id}
                pagination={false}
                scroll={{ x: true }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

function ProductRemoval(p: { authUserId: string; userId: string; product: BOProductFragment }) {
  const userId = p.userId;
  const productId = p.product.id;

  const [removeUserProduct] = useRemoveBOUserProductMutation({
    onError: (e) => message.error(e.message),
    refetchQueries: ([{ query: BOUserProductsDocument, variables: { userId } }] as PureQueryOptions[]).concat(
      userId === p.authUserId
        ? [{ query: ProductsDocument }, { query: ProductDocument, variables: { id: productId } }]
        : []
    ),
  });

  const onRemoveProductClick = useCallback(() => {
    Modal.confirm({
      title: 'Rimozione prodotto',
      content: (
        <>
          {'Confermi di voler rimuovere a questo utente l\'accesso al prodotto "'}
          <strong>
            {productId}: <span dangerouslySetInnerHTML={{ __html: p.product.description ?? '' }} />
          </strong>
          {'"?'}
        </>
      ),
      okText: 'Confermo',
      cancelText: 'Annulla',
      onOk: async () => {
        try {
          await removeUserProduct({ variables: { userId, productId } });
        } catch (e) {}
      },
    });
  }, [userId, productId, p.product.description, removeUserProduct]);

  return <Button onClick={onRemoveProductClick}>Rimuovi</Button>;
}

const PAGE_SIZE = 50;

function ProductAdditionModal(p: { authUserId: string; userId: string; visible?: boolean; closeFn?: () => void }) {
  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { loading, data } = useBOProductsQuery({
    variables: {
      searchKey: searchKey,
      start: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
    onError: (e) => message.error(e.message),
  });

  const columns: ColumnType<BOProductFragment>[] = [
    {
      title: 'Prodotto',
      key: 'description',
      render: (_, p) => (
        <strong>
          {p.id}: <span dangerouslySetInnerHTML={{ __html: p.description ?? '' }} />
        </strong>
      ),
    },
    {
      align: 'center',
      width: 130,
      key: 'addition',
      render: (_, prod) => <ProductAddition authUserId={p.authUserId} userId={p.userId} product={prod} />,
    },
  ];

  return (
    <Modal title="Aggiungi prodotti..." visible={p.visible} onCancel={p.closeFn} footer={null} width={767 - 31}>
      <div className="BackOffice">
        <Row>
          <Col span={24}>
            <Search
              placeholder="Inserisci un codice prodotto"
              enterButton="Cerca"
              onSearch={setSearchKey}
              loading={loading}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Table<BOProductFragment>
              columns={columns}
              dataSource={data?.products}
              rowKey={(p) => p.id}
              loading={loading}
              pagination={{
                onChange: setPage,
                pageSize: PAGE_SIZE,
                total: page * PAGE_SIZE + ((data?.products?.length ?? 0) < PAGE_SIZE ? 0 : 1),
              }}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

function ProductAddition(p: { authUserId: string; userId: string; product: BOProductFragment }) {
  const userId = p.userId;
  const productId = p.product.id;

  const [addUserProduct] = useAddBOUserProductMutation({
    onError: (e) => message.error(e.message),
    refetchQueries: ([{ query: BOUserProductsDocument, variables: { userId } }] as PureQueryOptions[]).concat(
      userId === p.authUserId
        ? [{ query: ProductsDocument }, { query: ProductDocument, variables: { id: productId } }]
        : []
    ),
  });

  const onAddProductClick = useCallback(() => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      title: 'Aggiunta prodotto',
      content: (
        <>
          {'Confermi di voler aggiungere a questo utente l\'accesso al prodotto "'}
          <strong>
            {productId}: <span dangerouslySetInnerHTML={{ __html: p.product.description ?? '' }} />
          </strong>
          {'"?'}
        </>
      ),
      okText: 'Confermo',
      cancelText: 'Annulla',
      onOk: async () => {
        try {
          if (
            (
              await addUserProduct({
                variables: { userId, productId },
              })
            ).data?.addUserProductInstance
          ) {
            message.success('Accesso al prodotto aggiunto!');
          } else {
            message.info('Accesso al prodotto già presente.');
          }
        } catch (e) {}
      },
    });
  }, [userId, productId, p.product.description, addUserProduct]);

  return <Button onClick={onAddProductClick}>Aggiungi</Button>;
}
