import { useCallback } from 'react';
import useLocalStorage from 'react-use-localstorage';

const AUDIO_VOLUME = 'AudioVolume';

export function useAudioVolume(defaultVolume: number) {
  const [volumeStr, setVolumeStr] = useLocalStorage(AUDIO_VOLUME, defaultVolume.toString());
  const volume = Number(volumeStr);

  const setVolume = useCallback(
    (audioVolume: number) => {
      setVolumeStr(audioVolume.toString());
    },
    [setVolumeStr]
  );

  return { volume: isNaN(volume) || volume < 0 || volume > 1 ? defaultVolume : volume, setVolume };
}

// https://stackoverflow.com/questions/12301435/html5-video-tag-volume-support
export function volumeChangeSupported() {
  var ua = navigator.userAgent.toLowerCase();
  // got information from jplayer:
  var noVolume = /ipad|iphone|ipod|android|blackberry|windows ce|windows phone|webos|playbook/.exec(ua);

  if (noVolume) {
    if (noVolume[0] === 'android' && /gecko/.test(ua)) {
      // Firefox on android DOES support changing the volume:
      return true;
    } else {
      return false;
    }
  }
  return true;
}
