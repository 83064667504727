import { useState } from 'react';
import { Col, Row, Table, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnType } from 'antd/lib/table';
import { BOProductFragment, useBOProductsQuery } from '../../apollo/api';
import { Link } from 'react-router-dom';

const PAGE_SIZE = 50;

export default function Products() {
  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { loading, data } = useBOProductsQuery({
    variables: {
      searchKey: searchKey,
      start: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
    onError: (e) => message.error(e.message),
  });

  const columns: ColumnType<BOProductFragment>[] = [
    {
      title: 'Prodotto',
      key: 'description',
      render: (_, p) => (
        <strong>
          {p.id}: <span dangerouslySetInnerHTML={{ __html: p.description ?? '' }} />
        </strong>
      ),
    },
    {
      align: 'center',
      width: 100,
      key: 'media',
      render: (_, p) => <Link to={(location) => `${location.pathname}/${p.id}`}>Media...</Link>,
    },
  ];

  return (
    <>
      <h1>Prodotti</h1>

      <div className="BackOffice">
        <div className="Content">
          <Row>
            <Col xs={24} xl={12}>
              <span>Ricerca Prodotto</span>
              <Search
                placeholder="Inserisci un codice prodotto"
                enterButton="Cerca"
                onSearch={setSearchKey}
                loading={loading}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Table<BOProductFragment>
                columns={columns}
                dataSource={data?.products}
                rowKey={(p) => p.id}
                loading={loading}
                pagination={{
                  onChange: setPage,
                  pageSize: PAGE_SIZE,
                  total: page * PAGE_SIZE + ((data?.products?.length ?? 0) < PAGE_SIZE ? 0 : 1),
                }}
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
