import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A coordinates object with latitude and longitude fields */
  Coordinates: any;
  /** A field whose value is a Country: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2. */
  Country: any;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  Decimal: any;
  Duration: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value is a Language: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes. */
  Language: any;
  /** A localized String with a translation for each supported locale */
  LocalizedString: any;
  SortDirection: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** Accepts any valid timezone. List of available [timezones is here](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) (column "TZ database name"). */
  Timezone: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};













export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}






export type DbAlignProblem = {
  __typename?: 'DbAlignProblem';
  productId: Scalars['String'];
  dbMissingKeys: Array<Scalars['String']>;
  s3MissingKeys: Array<Scalars['String']>;
  alreadyConvertedVideo: Array<Maybe<Scalars['String']>>;
  notConvertedVideo: Array<Maybe<Scalars['String']>>;
  convertedMediaToDelete: Array<Maybe<Scalars['String']>>;
};


export type Device = {
  __typename?: 'Device';
  id: Scalars['ID'];
  deviceId: Scalars['String'];
  userId: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  creationDate: Scalars['DateTime'];
  lastSeenDate: Scalars['DateTime'];
  lastSeenAddress?: Maybe<Scalars['String']>;
  live: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  osName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
};

export type DeviceEnablingHistory = {
  __typename?: 'DeviceEnablingHistory';
  id: Scalars['ID'];
  deviceId: Scalars['ID'];
  userId: Scalars['String'];
  date: Scalars['DateTime'];
};

export type DownloadRequest = {
  __typename?: 'DownloadRequest';
  key: Scalars['String'];
  preSignedURL: Scalars['String'];
};





export type Media = {
  __typename?: 'Media';
  key: Scalars['String'];
  type: MediaType;
  description: Scalars['String'];
  converted?: Maybe<Scalars['Boolean']>;
  downloadable?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  editor?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
};

export enum MediaType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  EPUB = 'EPUB'
}

export type MediaUsage = {
  __typename?: 'MediaUsage';
  id: Scalars['ID'];
  productId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  mediaKey: Scalars['String'];
  type: MediaUsageType;
  creationDate: Scalars['DateTime'];
};

export enum MediaUsageType {
  DOWNLOAD = 'DOWNLOAD',
  REPRODUCTION = 'REPRODUCTION'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Creates a new user with the given informatins.
   * Returns the jwt string that can be used when authentication in required.
   */
  register: Scalars['String'];
  /**
   * Binds the code of the product instance to the autheticated user.
   * Fails if the code was already redeemed.
   */
  bindProductInstanceToUser?: Maybe<ProductInstance>;
  /**
   * Presigned URLs are the mechanism that AWS S3 use to let clients securely download files. This
   * service return a presigned URL where a client can download a file. The URL expires after a
   * certain amount of time.
   */
  mediaDownloadPresignedURL: DownloadRequest;
  /**
   * After downloading a file, the client must explicitly create a new MediaUsage
   * in order to track the usage of the media.
   */
  setMediaUsage: Scalars['Boolean'];
  /**
   * Presigned URLs are the mechanism tha AWS S3 use to let clients securely upload files. This
   * service return a presigned URL where a client can upload a file. The URL expires after a
   * certain amount of time.
   */
  userAvatarUploadPresignedURL: UploadRequest;
  /**
   * After uploading a file to S3, the client must explicitly create a new File specifying the
   * file key returned by fileUploadPresignedURL service. This endpoint updates the user profile
   * picture url.
   */
  updateUserAvatar: Scalars['String'];
  /** Enable or disable a device. */
  updateDevice: Device;
  /** Removes a disabled device. */
  removeDevice: Scalars['Boolean'];
  /** Edits device informations. */
  editDevice: Device;
  /** Backoffice only. Fix all alignment problem found in "dbAlignProblem" */
  alignDatabaseWithS3: Scalars['Boolean'];
  /** Backoffice only. Start conversion for all media of a specific product or of all media if not specified. */
  convertMedias: Scalars['Boolean'];
  /** Sets admin status for a specific user. */
  setAdminStatus: Scalars['Boolean'];
  /** Sets unlimited device status for a specific user. */
  setDeviceLimitations: Scalars['Boolean'];
  /** Resets devices activations for a specific user. */
  resetDevicesActivations: Scalars['Boolean'];
  /** Delete all devices for a specific user. */
  clearDevices: Scalars['Boolean'];
  /** Set media info for a specific media. */
  setMediaInfo: Scalars['Boolean'];
  /** Deletes a product instance associated to a user. */
  deleteUserProductInstance: Scalars['Boolean'];
  /** Adds a product instance for a user. */
  addUserProductInstance: Scalars['Boolean'];
  /** Creates a new playlist. Empty if elements are not given. */
  createPlaylist: Playlist;
  /** Deletes the specified playlist. */
  deletePlaylist: Scalars['Boolean'];
  /** Update the specified playlist. Use this for change order of the elements in playlist. */
  updatePlaylist: Playlist;
  /** Add an element to a specific playlist. */
  addPlaylistElement: Playlist;
  /** Remove an element to a specific playlist. */
  removePlaylistElement: Playlist;
};


export type MutationregisterArgs = {
  credentials: UserCredential;
  userinfo?: Maybe<UserInfo>;
};


export type MutationbindProductInstanceToUserArgs = {
  code: Scalars['String'];
};


export type MutationmediaDownloadPresignedURLArgs = {
  productId: Scalars['String'];
  mediaKey: Scalars['String'];
  videoFormat?: Maybe<VideoFormat>;
};


export type MutationsetMediaUsageArgs = {
  productId: Scalars['String'];
  mediaKey: Scalars['String'];
  type: MediaUsageType;
};


export type MutationuserAvatarUploadPresignedURLArgs = {
  filename: Scalars['String'];
};


export type MutationupdateUserAvatarArgs = {
  key: Scalars['String'];
};


export type MutationupdateDeviceArgs = {
  deviceId: Scalars['ID'];
  enable: Scalars['Boolean'];
};


export type MutationremoveDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type MutationeditDeviceArgs = {
  deviceId: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};


export type MutationconvertMediasArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type MutationsetAdminStatusArgs = {
  userId: Scalars['ID'];
  admin: Scalars['Boolean'];
};


export type MutationsetDeviceLimitationsArgs = {
  userId: Scalars['ID'];
  unlimitedDeviceActivations: Scalars['Boolean'];
  unlimitedActiveDevices: Scalars['Boolean'];
};


export type MutationresetDevicesActivationsArgs = {
  userId: Scalars['ID'];
};


export type MutationclearDevicesArgs = {
  userId: Scalars['ID'];
};


export type MutationsetMediaInfoArgs = {
  productId: Scalars['String'];
  mediaKey: Scalars['String'];
  downloadable?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  editor?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
};


export type MutationdeleteUserProductInstanceArgs = {
  userId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationaddUserProductInstanceArgs = {
  userId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationcreatePlaylistArgs = {
  name?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<PlaylistElementInput>>;
};


export type MutationdeletePlaylistArgs = {
  id: Scalars['ID'];
};


export type MutationupdatePlaylistArgs = {
  id: Scalars['ID'];
  changes?: Maybe<PlaylistInput>;
};


export type MutationaddPlaylistElementArgs = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
  mediaKey: Scalars['String'];
};


export type MutationremovePlaylistElementArgs = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
  mediaKey: Scalars['String'];
};

export type PendingProductInstance = {
  __typename?: 'PendingProductInstance';
  id: Scalars['ID'];
  userEmail: Scalars['String'];
  productId: Scalars['String'];
};

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  lastUpdateDate: Scalars['DateTime'];
  elements: Array<PlaylistElement>;
  userId: Scalars['ID'];
  user?: Maybe<User>;
  live: Scalars['Boolean'];
};

export type PlaylistElement = {
  __typename?: 'PlaylistElement';
  productId: Scalars['ID'];
  product?: Maybe<Product>;
  mediaKey: Scalars['String'];
  media?: Maybe<Media>;
};

export type PlaylistElementInput = {
  productId: Scalars['ID'];
  mediaKey: Scalars['String'];
};

export type PlaylistInput = {
  name?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<PlaylistElementInput>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  media: Array<Media>;
  description?: Maybe<Scalars['String']>;
  img_url?: Maybe<Scalars['String']>;
};

export type ProductInstance = {
  __typename?: 'ProductInstance';
  id: Scalars['ID'];
  generated?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  productId: Scalars['ID'];
  product?: Maybe<Product>;
  mock?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  /** Given user credentials it returns the jwt string that can be used when authentication in required */
  login: Scalars['String'];
  /** Returns the user information. The id of the user is specified with authentication */
  user: User;
  /** Renews the jwt token, returns a new jwt token */
  refreshJwt: Scalars['String'];
  /**
   * If code is specified, returns the product instance with that code (if binded to the authenticated user).
   * If code is null, returns all the product instances binded to the authenticated user.
   */
  productInstances: Array<ProductInstance>;
  /** Returns all devices of the logged user. */
  devices: Array<Device>;
  /** Returns when the next device abilitation is possibile. Null if now. */
  nextDeviceEnablingDate?: Maybe<Scalars['DateTime']>;
  /** Returns when the next device abilitation is possibile. Null if now. */
  nextDeviceEnablingDateIfEnablingOne?: Maybe<Scalars['DateTime']>;
  /** Backoffice only. Find all alignment problem s3/db. */
  dbAlignProblem: Array<DbAlignProblem>;
  /** Gets users informations. */
  users: UsersQueryResult;
  /** Gets products informations. */
  products: Array<Product>;
  /** Gets usage report. */
  report: Report;
  /** Gets SIAE csv report of media usage. */
  siaeReport: DownloadRequest;
  /** Gets all the playlists of the logged user. Filter by id if specified. */
  playlists: Array<Playlist>;
};


export type QueryloginArgs = {
  credentials: UserCredential;
};


export type QueryuserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryproductInstancesArgs = {
  code?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryusersArgs = {
  filter: UserFilter;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryproductsArgs = {
  search?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryreportArgs = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
};


export type QuerysiaeReportArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
};


export type QueryplaylistsArgs = {
  id?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  mediaDownloads: Scalars['Int'];
  mediaReproductions: Scalars['Int'];
  activeUsers: Scalars['Int'];
  activatedProducts: Scalars['Int'];
};

export type SIAEEntry = {
  __typename?: 'SIAEEntry';
  productId: Scalars['String'];
  mediaKey: Scalars['String'];
  media: Scalars['String'];
  title: Scalars['String'];
  author: Scalars['String'];
  editor: Scalars['String'];
  interpreter: Scalars['String'];
  reproductions: Scalars['Int'];
  downloads: Scalars['Int'];
};

export type SIAEReport = {
  __typename?: 'SIAEReport';
  id: Scalars['ID'];
  retrieveId: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  entries: Array<SIAEEntry>;
};






export type UploadRequest = {
  __typename?: 'UploadRequest';
  key: Scalars['String'];
  preSignedURL: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  profilePicture?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  unlimitedDeviceActivations?: Maybe<Scalars['Boolean']>;
  unlimitedActiveDevices?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  enabledDevicesInPeriodCount?: Maybe<Scalars['Int']>;
  enabledDevicesCount?: Maybe<Scalars['Int']>;
  productsCount?: Maybe<Scalars['Int']>;
};

export type UserBilling = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UserCredential = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserFilter = {
  email?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  billing?: Maybe<UserBilling>;
  shipping?: Maybe<UserShipping>;
};

export type UserShipping = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UsersQueryResult = {
  __typename?: 'UsersQueryResult';
  users: Array<User>;
  resultCount: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export enum VideoFormat {
  HLS = 'HLS'
}

export type AuthTokenQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'login'>
);

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type DevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DevicesQuery = (
  { __typename?: 'Query' }
  & { devices: Array<(
    { __typename?: 'Device' }
    & Pick<Device, 'id' | 'deviceId' | 'label' | 'type' | 'vendor' | 'model' | 'osName' | 'lastSeenDate' | 'enabled'>
  )> }
);

export type DeviceDisablingAlertQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceDisablingAlertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nextDeviceEnablingDate'>
);

export type DeviceEnablingAlertQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceEnablingAlertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nextDeviceEnablingDate' | 'nextDeviceEnablingDateIfEnablingOne'>
);

export type RemoveDeviceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDevice'>
);

export type SetDeviceEnabledMutationVariables = Exact<{
  deviceId: Scalars['ID'];
  enabled: Scalars['Boolean'];
}>;


export type SetDeviceEnabledMutation = (
  { __typename?: 'Mutation' }
  & { updateDevice: (
    { __typename?: 'Device' }
    & Pick<Device, 'id' | 'enabled'>
  ) }
);

export type PlaylistElementsFragment = (
  { __typename?: 'Playlist' }
  & { elements: Array<(
    { __typename?: 'PlaylistElement' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'img_url'>
    )>, media?: Maybe<(
      { __typename?: 'Media' }
      & MediaFragment
    )> }
  )> }
);

export type PlaylistFragment = (
  { __typename?: 'Playlist' }
  & Pick<Playlist, 'id' | 'name' | 'creationDate' | 'lastUpdateDate'>
  & PlaylistElementsFragment
);

export type PlaylistsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlaylistsQuery = (
  { __typename?: 'Query' }
  & { playlists: Array<(
    { __typename?: 'Playlist' }
    & PlaylistFragment
  )> }
);

export type PlaylistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlaylistQuery = (
  { __typename?: 'Query' }
  & { playlists: Array<(
    { __typename?: 'Playlist' }
    & PlaylistFragment
  )> }
);

export type CreatePlaylistMutationVariables = Exact<{
  name: Scalars['String'];
  elements?: Maybe<Array<PlaylistElementInput> | PlaylistElementInput>;
}>;


export type CreatePlaylistMutation = (
  { __typename?: 'Mutation' }
  & { createPlaylist: (
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id'>
  ) }
);

export type AddPlaylistElementMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  productId: Scalars['ID'];
  mediaKey: Scalars['String'];
}>;


export type AddPlaylistElementMutation = (
  { __typename?: 'Mutation' }
  & { addPlaylistElement: (
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id'>
    & PlaylistElementsFragment
  ) }
);

export type RemovePlaylistElementMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  productId: Scalars['ID'];
  mediaKey: Scalars['String'];
}>;


export type RemovePlaylistElementMutation = (
  { __typename?: 'Mutation' }
  & { removePlaylistElement: (
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id'>
    & PlaylistElementsFragment
  ) }
);

export type DeletePlaylistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlaylistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaylist'>
);

export type SetPlaylistNameMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type SetPlaylistNameMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaylist: (
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id' | 'name'>
  ) }
);

export type SetPlaylistElementsMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  elements?: Maybe<Array<PlaylistElementInput> | PlaylistElementInput>;
}>;


export type SetPlaylistElementsMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaylist: (
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'id'>
    & PlaylistElementsFragment
  ) }
);

export type ProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'description' | 'img_url'>
);

export type MediaFragment = (
  { __typename?: 'Media' }
  & Pick<Media, 'key' | 'type' | 'description' | 'downloadable'>
);

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { productInstances: Array<(
    { __typename?: 'ProductInstance' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & ProductFragment
    )> }
  )> }
);

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { productInstances: Array<(
    { __typename?: 'ProductInstance' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & { media: Array<(
        { __typename?: 'Media' }
        & MediaFragment
      )> }
      & ProductFragment
    )> }
  )> }
);

export type GetMediaURLMutationVariables = Exact<{
  productId: Scalars['String'];
  key: Scalars['String'];
}>;


export type GetMediaURLMutation = (
  { __typename?: 'Mutation' }
  & { mediaDownloadPresignedURL: (
    { __typename?: 'DownloadRequest' }
    & Pick<DownloadRequest, 'preSignedURL'>
  ) }
);

export type GetMediaDownloadURLMutationVariables = Exact<{
  productId: Scalars['String'];
  key: Scalars['String'];
}>;


export type GetMediaDownloadURLMutation = (
  { __typename?: 'Mutation' }
  & { mediaDownloadPresignedURL: (
    { __typename?: 'DownloadRequest' }
    & Pick<DownloadRequest, 'preSignedURL'>
  ) }
);

export type TrackMediaPlaybackMutationVariables = Exact<{
  productId: Scalars['String'];
  key: Scalars['String'];
}>;


export type TrackMediaPlaybackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMediaUsage'>
);

export type TrackMediaDownloadMutationVariables = Exact<{
  productId: Scalars['String'];
  key: Scalars['String'];
}>;


export type TrackMediaDownloadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMediaUsage'>
);

export type BindProductInstanceMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type BindProductInstanceMutation = (
  { __typename?: 'Mutation' }
  & { bindProductInstanceToUser?: Maybe<(
    { __typename?: 'ProductInstance' }
    & Pick<ProductInstance, 'productId'>
  )> }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'profilePicture' | 'admin'>
  ) }
);

export type GetAvatarUploadURLMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type GetAvatarUploadURLMutation = (
  { __typename?: 'Mutation' }
  & { userAvatarUploadPresignedURL: (
    { __typename?: 'UploadRequest' }
    & Pick<UploadRequest, 'key' | 'preSignedURL'>
  ) }
);

export type SetAvatarMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type SetAvatarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserAvatar'>
);

export type BOProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'description'>
);

export type BOProductMediaFragment = (
  { __typename?: 'Product' }
  & { media: Array<(
    { __typename?: 'Media' }
    & BOMediaFragment
  )> }
);

export type BOMediaFragment = (
  { __typename?: 'Media' }
  & Pick<Media, 'key' | 'description' | 'title' | 'author' | 'editor' | 'interpreter' | 'downloadable'>
);

export type BOProductsQueryVariables = Exact<{
  searchKey: Scalars['String'];
  start?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type BOProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & BOProductFragment
  )> }
);

export type BOProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BOProductQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & BOProductFragment
    & BOProductMediaFragment
  )> }
);

export type SetBOMediaInfoMutationVariables = Exact<{
  productId: Scalars['String'];
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  editor?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
  downloadable?: Maybe<Scalars['Boolean']>;
}>;


export type SetBOMediaInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMediaInfo'>
);

export type BOInternalReportQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
}>;


export type BOInternalReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'activeUsers' | 'activatedProducts' | 'mediaReproductions' | 'mediaDownloads'>
  ) }
);

export type BOSIAEReportQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type BOSIAEReportQuery = (
  { __typename?: 'Query' }
  & { siaeReport: (
    { __typename?: 'DownloadRequest' }
    & Pick<DownloadRequest, 'preSignedURL'>
  ) }
);

export type BOUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'unlimitedActiveDevices' | 'enabledDevicesInPeriodCount' | 'enabledDevicesCount' | 'productsCount'>
);

export type BOUsersQueryVariables = Exact<{
  searchKey: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type BOUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UsersQueryResult' }
    & Pick<UsersQueryResult, 'resultCount'>
    & { users: Array<(
      { __typename?: 'User' }
      & BOUserFragment
    )> }
  ) }
);

export type SetBOUserDeviceLimitationsMutationVariables = Exact<{
  userId: Scalars['ID'];
  unlimitedDeviceActivations: Scalars['Boolean'];
  unlimitedActiveDevices: Scalars['Boolean'];
}>;


export type SetBOUserDeviceLimitationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDeviceLimitations'>
);

export type ResetBOUserDeviceActivationsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ResetBOUserDeviceActivationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetDevicesActivations'>
);

export type BOUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BOUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name'>
  ) }
);

export type BOUserProductsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type BOUserProductsQuery = (
  { __typename?: 'Query' }
  & { productInstances: Array<(
    { __typename?: 'ProductInstance' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & BOProductFragment
    )> }
  )> }
);

export type RemoveBOUserProductMutationVariables = Exact<{
  userId: Scalars['ID'];
  productId: Scalars['ID'];
}>;


export type RemoveBOUserProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserProductInstance'>
);

export type AddBOUserProductMutationVariables = Exact<{
  userId: Scalars['ID'];
  productId: Scalars['ID'];
}>;


export type AddBOUserProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUserProductInstance'>
);

export const MediaFragmentDoc = gql`
    fragment Media on Media {
  key
  type
  description
  downloadable
}
    `;
export const PlaylistElementsFragmentDoc = gql`
    fragment PlaylistElements on Playlist {
  elements {
    product {
      id
      img_url
    }
    media {
      ...Media
    }
  }
}
    ${MediaFragmentDoc}`;
export const PlaylistFragmentDoc = gql`
    fragment Playlist on Playlist {
  id
  name
  ...PlaylistElements
  creationDate
  lastUpdateDate
}
    ${PlaylistElementsFragmentDoc}`;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  description
  img_url
}
    `;
export const BOProductFragmentDoc = gql`
    fragment BOProduct on Product {
  id
  description
}
    `;
export const BOMediaFragmentDoc = gql`
    fragment BOMedia on Media {
  key
  description
  title
  author
  editor
  interpreter
  downloadable
}
    `;
export const BOProductMediaFragmentDoc = gql`
    fragment BOProductMedia on Product {
  media {
    ...BOMedia
  }
}
    ${BOMediaFragmentDoc}`;
export const BOUserFragmentDoc = gql`
    fragment BOUser on User {
  id
  email
  first_name
  last_name
  unlimitedActiveDevices
  enabledDevicesInPeriodCount
  enabledDevicesCount
  productsCount
}
    `;
export const AuthTokenDocument = gql`
    query AuthToken($email: String!, $password: String!) {
  login(credentials: {email: $email, password: $password})
}
    `;

/**
 * __useAuthTokenQuery__
 *
 * To run a query within a React component, call `useAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthTokenQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthTokenQuery(baseOptions: Apollo.QueryHookOptions<AuthTokenQuery, AuthTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthTokenQuery, AuthTokenQueryVariables>(AuthTokenDocument, options);
      }
export function useAuthTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthTokenQuery, AuthTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthTokenQuery, AuthTokenQueryVariables>(AuthTokenDocument, options);
        }
export type AuthTokenQueryHookResult = ReturnType<typeof useAuthTokenQuery>;
export type AuthTokenLazyQueryHookResult = ReturnType<typeof useAuthTokenLazyQuery>;
export type AuthTokenQueryResult = Apollo.QueryResult<AuthTokenQuery, AuthTokenQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($email: String!, $password: String!, $firstName: String, $lastName: String) {
  register(
    credentials: {email: $email, password: $password}
    userinfo: {first_name: $firstName, last_name: $lastName}
  )
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const DevicesDocument = gql`
    query Devices {
  devices {
    id
    deviceId
    label
    type
    vendor
    model
    osName
    lastSeenDate
    enabled
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const DeviceDisablingAlertDocument = gql`
    query DeviceDisablingAlert {
  nextDeviceEnablingDate
}
    `;

/**
 * __useDeviceDisablingAlertQuery__
 *
 * To run a query within a React component, call `useDeviceDisablingAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDisablingAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDisablingAlertQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceDisablingAlertQuery(baseOptions?: Apollo.QueryHookOptions<DeviceDisablingAlertQuery, DeviceDisablingAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDisablingAlertQuery, DeviceDisablingAlertQueryVariables>(DeviceDisablingAlertDocument, options);
      }
export function useDeviceDisablingAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDisablingAlertQuery, DeviceDisablingAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDisablingAlertQuery, DeviceDisablingAlertQueryVariables>(DeviceDisablingAlertDocument, options);
        }
export type DeviceDisablingAlertQueryHookResult = ReturnType<typeof useDeviceDisablingAlertQuery>;
export type DeviceDisablingAlertLazyQueryHookResult = ReturnType<typeof useDeviceDisablingAlertLazyQuery>;
export type DeviceDisablingAlertQueryResult = Apollo.QueryResult<DeviceDisablingAlertQuery, DeviceDisablingAlertQueryVariables>;
export const DeviceEnablingAlertDocument = gql`
    query DeviceEnablingAlert {
  nextDeviceEnablingDate
  nextDeviceEnablingDateIfEnablingOne
}
    `;

/**
 * __useDeviceEnablingAlertQuery__
 *
 * To run a query within a React component, call `useDeviceEnablingAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEnablingAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEnablingAlertQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceEnablingAlertQuery(baseOptions?: Apollo.QueryHookOptions<DeviceEnablingAlertQuery, DeviceEnablingAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceEnablingAlertQuery, DeviceEnablingAlertQueryVariables>(DeviceEnablingAlertDocument, options);
      }
export function useDeviceEnablingAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceEnablingAlertQuery, DeviceEnablingAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceEnablingAlertQuery, DeviceEnablingAlertQueryVariables>(DeviceEnablingAlertDocument, options);
        }
export type DeviceEnablingAlertQueryHookResult = ReturnType<typeof useDeviceEnablingAlertQuery>;
export type DeviceEnablingAlertLazyQueryHookResult = ReturnType<typeof useDeviceEnablingAlertLazyQuery>;
export type DeviceEnablingAlertQueryResult = Apollo.QueryResult<DeviceEnablingAlertQuery, DeviceEnablingAlertQueryVariables>;
export const RemoveDeviceDocument = gql`
    mutation RemoveDevice($id: ID!) {
  removeDevice(deviceId: $id)
}
    `;
export type RemoveDeviceMutationFn = Apollo.MutationFunction<RemoveDeviceMutation, RemoveDeviceMutationVariables>;

/**
 * __useRemoveDeviceMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceMutation, { data, loading, error }] = useRemoveDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDeviceMutation, RemoveDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDeviceMutation, RemoveDeviceMutationVariables>(RemoveDeviceDocument, options);
      }
export type RemoveDeviceMutationHookResult = ReturnType<typeof useRemoveDeviceMutation>;
export type RemoveDeviceMutationResult = Apollo.MutationResult<RemoveDeviceMutation>;
export type RemoveDeviceMutationOptions = Apollo.BaseMutationOptions<RemoveDeviceMutation, RemoveDeviceMutationVariables>;
export const SetDeviceEnabledDocument = gql`
    mutation SetDeviceEnabled($deviceId: ID!, $enabled: Boolean!) {
  updateDevice(deviceId: $deviceId, enable: $enabled) {
    id
    enabled
  }
}
    `;
export type SetDeviceEnabledMutationFn = Apollo.MutationFunction<SetDeviceEnabledMutation, SetDeviceEnabledMutationVariables>;

/**
 * __useSetDeviceEnabledMutation__
 *
 * To run a mutation, you first call `useSetDeviceEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeviceEnabledMutation, { data, loading, error }] = useSetDeviceEnabledMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetDeviceEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetDeviceEnabledMutation, SetDeviceEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDeviceEnabledMutation, SetDeviceEnabledMutationVariables>(SetDeviceEnabledDocument, options);
      }
export type SetDeviceEnabledMutationHookResult = ReturnType<typeof useSetDeviceEnabledMutation>;
export type SetDeviceEnabledMutationResult = Apollo.MutationResult<SetDeviceEnabledMutation>;
export type SetDeviceEnabledMutationOptions = Apollo.BaseMutationOptions<SetDeviceEnabledMutation, SetDeviceEnabledMutationVariables>;
export const PlaylistsDocument = gql`
    query Playlists {
  playlists {
    ...Playlist
  }
}
    ${PlaylistFragmentDoc}`;

/**
 * __usePlaylistsQuery__
 *
 * To run a query within a React component, call `usePlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaylistsQuery(baseOptions?: Apollo.QueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
      }
export function usePlaylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
        }
export type PlaylistsQueryHookResult = ReturnType<typeof usePlaylistsQuery>;
export type PlaylistsLazyQueryHookResult = ReturnType<typeof usePlaylistsLazyQuery>;
export type PlaylistsQueryResult = Apollo.QueryResult<PlaylistsQuery, PlaylistsQueryVariables>;
export const PlaylistDocument = gql`
    query Playlist($id: ID!) {
  playlists(id: $id) {
    ...Playlist
  }
}
    ${PlaylistFragmentDoc}`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistQuery(baseOptions: Apollo.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
      }
export function usePlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
        }
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<typeof usePlaylistLazyQuery>;
export type PlaylistQueryResult = Apollo.QueryResult<PlaylistQuery, PlaylistQueryVariables>;
export const CreatePlaylistDocument = gql`
    mutation CreatePlaylist($name: String!, $elements: [PlaylistElementInput!]) {
  createPlaylist(name: $name, elements: $elements) {
    id
  }
}
    `;
export type CreatePlaylistMutationFn = Apollo.MutationFunction<CreatePlaylistMutation, CreatePlaylistMutationVariables>;

/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      name: // value for 'name'
 *      elements: // value for 'elements'
 *   },
 * });
 */
export function useCreatePlaylistMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaylistMutation, CreatePlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaylistMutation, CreatePlaylistMutationVariables>(CreatePlaylistDocument, options);
      }
export type CreatePlaylistMutationHookResult = ReturnType<typeof useCreatePlaylistMutation>;
export type CreatePlaylistMutationResult = Apollo.MutationResult<CreatePlaylistMutation>;
export type CreatePlaylistMutationOptions = Apollo.BaseMutationOptions<CreatePlaylistMutation, CreatePlaylistMutationVariables>;
export const AddPlaylistElementDocument = gql`
    mutation AddPlaylistElement($playlistId: ID!, $productId: ID!, $mediaKey: String!) {
  addPlaylistElement(id: $playlistId, productId: $productId, mediaKey: $mediaKey) {
    id
    ...PlaylistElements
  }
}
    ${PlaylistElementsFragmentDoc}`;
export type AddPlaylistElementMutationFn = Apollo.MutationFunction<AddPlaylistElementMutation, AddPlaylistElementMutationVariables>;

/**
 * __useAddPlaylistElementMutation__
 *
 * To run a mutation, you first call `useAddPlaylistElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlaylistElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlaylistElementMutation, { data, loading, error }] = useAddPlaylistElementMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      productId: // value for 'productId'
 *      mediaKey: // value for 'mediaKey'
 *   },
 * });
 */
export function useAddPlaylistElementMutation(baseOptions?: Apollo.MutationHookOptions<AddPlaylistElementMutation, AddPlaylistElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlaylistElementMutation, AddPlaylistElementMutationVariables>(AddPlaylistElementDocument, options);
      }
export type AddPlaylistElementMutationHookResult = ReturnType<typeof useAddPlaylistElementMutation>;
export type AddPlaylistElementMutationResult = Apollo.MutationResult<AddPlaylistElementMutation>;
export type AddPlaylistElementMutationOptions = Apollo.BaseMutationOptions<AddPlaylistElementMutation, AddPlaylistElementMutationVariables>;
export const RemovePlaylistElementDocument = gql`
    mutation RemovePlaylistElement($playlistId: ID!, $productId: ID!, $mediaKey: String!) {
  removePlaylistElement(
    id: $playlistId
    productId: $productId
    mediaKey: $mediaKey
  ) {
    id
    ...PlaylistElements
  }
}
    ${PlaylistElementsFragmentDoc}`;
export type RemovePlaylistElementMutationFn = Apollo.MutationFunction<RemovePlaylistElementMutation, RemovePlaylistElementMutationVariables>;

/**
 * __useRemovePlaylistElementMutation__
 *
 * To run a mutation, you first call `useRemovePlaylistElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlaylistElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlaylistElementMutation, { data, loading, error }] = useRemovePlaylistElementMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      productId: // value for 'productId'
 *      mediaKey: // value for 'mediaKey'
 *   },
 * });
 */
export function useRemovePlaylistElementMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlaylistElementMutation, RemovePlaylistElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlaylistElementMutation, RemovePlaylistElementMutationVariables>(RemovePlaylistElementDocument, options);
      }
export type RemovePlaylistElementMutationHookResult = ReturnType<typeof useRemovePlaylistElementMutation>;
export type RemovePlaylistElementMutationResult = Apollo.MutationResult<RemovePlaylistElementMutation>;
export type RemovePlaylistElementMutationOptions = Apollo.BaseMutationOptions<RemovePlaylistElementMutation, RemovePlaylistElementMutationVariables>;
export const DeletePlaylistDocument = gql`
    mutation DeletePlaylist($id: ID!) {
  deletePlaylist(id: $id)
}
    `;
export type DeletePlaylistMutationFn = Apollo.MutationFunction<DeletePlaylistMutation, DeletePlaylistMutationVariables>;

/**
 * __useDeletePlaylistMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistMutation, { data, loading, error }] = useDeletePlaylistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaylistMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaylistMutation, DeletePlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaylistMutation, DeletePlaylistMutationVariables>(DeletePlaylistDocument, options);
      }
export type DeletePlaylistMutationHookResult = ReturnType<typeof useDeletePlaylistMutation>;
export type DeletePlaylistMutationResult = Apollo.MutationResult<DeletePlaylistMutation>;
export type DeletePlaylistMutationOptions = Apollo.BaseMutationOptions<DeletePlaylistMutation, DeletePlaylistMutationVariables>;
export const SetPlaylistNameDocument = gql`
    mutation SetPlaylistName($playlistId: ID!, $name: String!) {
  updatePlaylist(id: $playlistId, changes: {name: $name}) {
    id
    name
  }
}
    `;
export type SetPlaylistNameMutationFn = Apollo.MutationFunction<SetPlaylistNameMutation, SetPlaylistNameMutationVariables>;

/**
 * __useSetPlaylistNameMutation__
 *
 * To run a mutation, you first call `useSetPlaylistNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlaylistNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlaylistNameMutation, { data, loading, error }] = useSetPlaylistNameMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetPlaylistNameMutation(baseOptions?: Apollo.MutationHookOptions<SetPlaylistNameMutation, SetPlaylistNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlaylistNameMutation, SetPlaylistNameMutationVariables>(SetPlaylistNameDocument, options);
      }
export type SetPlaylistNameMutationHookResult = ReturnType<typeof useSetPlaylistNameMutation>;
export type SetPlaylistNameMutationResult = Apollo.MutationResult<SetPlaylistNameMutation>;
export type SetPlaylistNameMutationOptions = Apollo.BaseMutationOptions<SetPlaylistNameMutation, SetPlaylistNameMutationVariables>;
export const SetPlaylistElementsDocument = gql`
    mutation SetPlaylistElements($playlistId: ID!, $elements: [PlaylistElementInput!]) {
  updatePlaylist(id: $playlistId, changes: {elements: $elements}) {
    id
    ...PlaylistElements
  }
}
    ${PlaylistElementsFragmentDoc}`;
export type SetPlaylistElementsMutationFn = Apollo.MutationFunction<SetPlaylistElementsMutation, SetPlaylistElementsMutationVariables>;

/**
 * __useSetPlaylistElementsMutation__
 *
 * To run a mutation, you first call `useSetPlaylistElementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlaylistElementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlaylistElementsMutation, { data, loading, error }] = useSetPlaylistElementsMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      elements: // value for 'elements'
 *   },
 * });
 */
export function useSetPlaylistElementsMutation(baseOptions?: Apollo.MutationHookOptions<SetPlaylistElementsMutation, SetPlaylistElementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlaylistElementsMutation, SetPlaylistElementsMutationVariables>(SetPlaylistElementsDocument, options);
      }
export type SetPlaylistElementsMutationHookResult = ReturnType<typeof useSetPlaylistElementsMutation>;
export type SetPlaylistElementsMutationResult = Apollo.MutationResult<SetPlaylistElementsMutation>;
export type SetPlaylistElementsMutationOptions = Apollo.BaseMutationOptions<SetPlaylistElementsMutation, SetPlaylistElementsMutationVariables>;
export const ProductsDocument = gql`
    query Products {
  productInstances {
    product {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: String!) {
  productInstances(productId: $id) {
    product {
      ...Product
      media {
        ...Media
      }
    }
  }
}
    ${ProductFragmentDoc}
${MediaFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const GetMediaURLDocument = gql`
    mutation GetMediaURL($productId: String!, $key: String!) {
  mediaDownloadPresignedURL(
    productId: $productId
    mediaKey: $key
    videoFormat: HLS
  ) {
    preSignedURL
  }
}
    `;
export type GetMediaURLMutationFn = Apollo.MutationFunction<GetMediaURLMutation, GetMediaURLMutationVariables>;

/**
 * __useGetMediaURLMutation__
 *
 * To run a mutation, you first call `useGetMediaURLMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMediaURLMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMediaUrlMutation, { data, loading, error }] = useGetMediaURLMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetMediaURLMutation(baseOptions?: Apollo.MutationHookOptions<GetMediaURLMutation, GetMediaURLMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetMediaURLMutation, GetMediaURLMutationVariables>(GetMediaURLDocument, options);
      }
export type GetMediaURLMutationHookResult = ReturnType<typeof useGetMediaURLMutation>;
export type GetMediaURLMutationResult = Apollo.MutationResult<GetMediaURLMutation>;
export type GetMediaURLMutationOptions = Apollo.BaseMutationOptions<GetMediaURLMutation, GetMediaURLMutationVariables>;
export const GetMediaDownloadURLDocument = gql`
    mutation GetMediaDownloadURL($productId: String!, $key: String!) {
  mediaDownloadPresignedURL(productId: $productId, mediaKey: $key) {
    preSignedURL
  }
}
    `;
export type GetMediaDownloadURLMutationFn = Apollo.MutationFunction<GetMediaDownloadURLMutation, GetMediaDownloadURLMutationVariables>;

/**
 * __useGetMediaDownloadURLMutation__
 *
 * To run a mutation, you first call `useGetMediaDownloadURLMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMediaDownloadURLMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMediaDownloadUrlMutation, { data, loading, error }] = useGetMediaDownloadURLMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetMediaDownloadURLMutation(baseOptions?: Apollo.MutationHookOptions<GetMediaDownloadURLMutation, GetMediaDownloadURLMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetMediaDownloadURLMutation, GetMediaDownloadURLMutationVariables>(GetMediaDownloadURLDocument, options);
      }
export type GetMediaDownloadURLMutationHookResult = ReturnType<typeof useGetMediaDownloadURLMutation>;
export type GetMediaDownloadURLMutationResult = Apollo.MutationResult<GetMediaDownloadURLMutation>;
export type GetMediaDownloadURLMutationOptions = Apollo.BaseMutationOptions<GetMediaDownloadURLMutation, GetMediaDownloadURLMutationVariables>;
export const TrackMediaPlaybackDocument = gql`
    mutation TrackMediaPlayback($productId: String!, $key: String!) {
  setMediaUsage(productId: $productId, mediaKey: $key, type: REPRODUCTION)
}
    `;
export type TrackMediaPlaybackMutationFn = Apollo.MutationFunction<TrackMediaPlaybackMutation, TrackMediaPlaybackMutationVariables>;

/**
 * __useTrackMediaPlaybackMutation__
 *
 * To run a mutation, you first call `useTrackMediaPlaybackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackMediaPlaybackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackMediaPlaybackMutation, { data, loading, error }] = useTrackMediaPlaybackMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useTrackMediaPlaybackMutation(baseOptions?: Apollo.MutationHookOptions<TrackMediaPlaybackMutation, TrackMediaPlaybackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackMediaPlaybackMutation, TrackMediaPlaybackMutationVariables>(TrackMediaPlaybackDocument, options);
      }
export type TrackMediaPlaybackMutationHookResult = ReturnType<typeof useTrackMediaPlaybackMutation>;
export type TrackMediaPlaybackMutationResult = Apollo.MutationResult<TrackMediaPlaybackMutation>;
export type TrackMediaPlaybackMutationOptions = Apollo.BaseMutationOptions<TrackMediaPlaybackMutation, TrackMediaPlaybackMutationVariables>;
export const TrackMediaDownloadDocument = gql`
    mutation TrackMediaDownload($productId: String!, $key: String!) {
  setMediaUsage(productId: $productId, mediaKey: $key, type: DOWNLOAD)
}
    `;
export type TrackMediaDownloadMutationFn = Apollo.MutationFunction<TrackMediaDownloadMutation, TrackMediaDownloadMutationVariables>;

/**
 * __useTrackMediaDownloadMutation__
 *
 * To run a mutation, you first call `useTrackMediaDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackMediaDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackMediaDownloadMutation, { data, loading, error }] = useTrackMediaDownloadMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useTrackMediaDownloadMutation(baseOptions?: Apollo.MutationHookOptions<TrackMediaDownloadMutation, TrackMediaDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackMediaDownloadMutation, TrackMediaDownloadMutationVariables>(TrackMediaDownloadDocument, options);
      }
export type TrackMediaDownloadMutationHookResult = ReturnType<typeof useTrackMediaDownloadMutation>;
export type TrackMediaDownloadMutationResult = Apollo.MutationResult<TrackMediaDownloadMutation>;
export type TrackMediaDownloadMutationOptions = Apollo.BaseMutationOptions<TrackMediaDownloadMutation, TrackMediaDownloadMutationVariables>;
export const BindProductInstanceDocument = gql`
    mutation BindProductInstance($code: String!) {
  bindProductInstanceToUser(code: $code) {
    productId
  }
}
    `;
export type BindProductInstanceMutationFn = Apollo.MutationFunction<BindProductInstanceMutation, BindProductInstanceMutationVariables>;

/**
 * __useBindProductInstanceMutation__
 *
 * To run a mutation, you first call `useBindProductInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindProductInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindProductInstanceMutation, { data, loading, error }] = useBindProductInstanceMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useBindProductInstanceMutation(baseOptions?: Apollo.MutationHookOptions<BindProductInstanceMutation, BindProductInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BindProductInstanceMutation, BindProductInstanceMutationVariables>(BindProductInstanceDocument, options);
      }
export type BindProductInstanceMutationHookResult = ReturnType<typeof useBindProductInstanceMutation>;
export type BindProductInstanceMutationResult = Apollo.MutationResult<BindProductInstanceMutation>;
export type BindProductInstanceMutationOptions = Apollo.BaseMutationOptions<BindProductInstanceMutation, BindProductInstanceMutationVariables>;
export const UserDocument = gql`
    query User {
  user {
    id
    email
    first_name
    last_name
    profilePicture
    admin
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const GetAvatarUploadURLDocument = gql`
    mutation GetAvatarUploadURL($fileName: String!) {
  userAvatarUploadPresignedURL(filename: $fileName) {
    key
    preSignedURL
  }
}
    `;
export type GetAvatarUploadURLMutationFn = Apollo.MutationFunction<GetAvatarUploadURLMutation, GetAvatarUploadURLMutationVariables>;

/**
 * __useGetAvatarUploadURLMutation__
 *
 * To run a mutation, you first call `useGetAvatarUploadURLMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarUploadURLMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAvatarUploadUrlMutation, { data, loading, error }] = useGetAvatarUploadURLMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetAvatarUploadURLMutation(baseOptions?: Apollo.MutationHookOptions<GetAvatarUploadURLMutation, GetAvatarUploadURLMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAvatarUploadURLMutation, GetAvatarUploadURLMutationVariables>(GetAvatarUploadURLDocument, options);
      }
export type GetAvatarUploadURLMutationHookResult = ReturnType<typeof useGetAvatarUploadURLMutation>;
export type GetAvatarUploadURLMutationResult = Apollo.MutationResult<GetAvatarUploadURLMutation>;
export type GetAvatarUploadURLMutationOptions = Apollo.BaseMutationOptions<GetAvatarUploadURLMutation, GetAvatarUploadURLMutationVariables>;
export const SetAvatarDocument = gql`
    mutation SetAvatar($key: String!) {
  updateUserAvatar(key: $key)
}
    `;
export type SetAvatarMutationFn = Apollo.MutationFunction<SetAvatarMutation, SetAvatarMutationVariables>;

/**
 * __useSetAvatarMutation__
 *
 * To run a mutation, you first call `useSetAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAvatarMutation, { data, loading, error }] = useSetAvatarMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSetAvatarMutation(baseOptions?: Apollo.MutationHookOptions<SetAvatarMutation, SetAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAvatarMutation, SetAvatarMutationVariables>(SetAvatarDocument, options);
      }
export type SetAvatarMutationHookResult = ReturnType<typeof useSetAvatarMutation>;
export type SetAvatarMutationResult = Apollo.MutationResult<SetAvatarMutation>;
export type SetAvatarMutationOptions = Apollo.BaseMutationOptions<SetAvatarMutation, SetAvatarMutationVariables>;
export const BOProductsDocument = gql`
    query BOProducts($searchKey: String!, $start: Int, $limit: Int) {
  products(search: $searchKey, start: $start, limit: $limit) {
    ...BOProduct
  }
}
    ${BOProductFragmentDoc}`;

/**
 * __useBOProductsQuery__
 *
 * To run a query within a React component, call `useBOProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOProductsQuery({
 *   variables: {
 *      searchKey: // value for 'searchKey'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBOProductsQuery(baseOptions: Apollo.QueryHookOptions<BOProductsQuery, BOProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOProductsQuery, BOProductsQueryVariables>(BOProductsDocument, options);
      }
export function useBOProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOProductsQuery, BOProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOProductsQuery, BOProductsQueryVariables>(BOProductsDocument, options);
        }
export type BOProductsQueryHookResult = ReturnType<typeof useBOProductsQuery>;
export type BOProductsLazyQueryHookResult = ReturnType<typeof useBOProductsLazyQuery>;
export type BOProductsQueryResult = Apollo.QueryResult<BOProductsQuery, BOProductsQueryVariables>;
export const BOProductDocument = gql`
    query BOProduct($id: String!) {
  products(id: $id) {
    ...BOProduct
    ...BOProductMedia
  }
}
    ${BOProductFragmentDoc}
${BOProductMediaFragmentDoc}`;

/**
 * __useBOProductQuery__
 *
 * To run a query within a React component, call `useBOProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBOProductQuery(baseOptions: Apollo.QueryHookOptions<BOProductQuery, BOProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOProductQuery, BOProductQueryVariables>(BOProductDocument, options);
      }
export function useBOProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOProductQuery, BOProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOProductQuery, BOProductQueryVariables>(BOProductDocument, options);
        }
export type BOProductQueryHookResult = ReturnType<typeof useBOProductQuery>;
export type BOProductLazyQueryHookResult = ReturnType<typeof useBOProductLazyQuery>;
export type BOProductQueryResult = Apollo.QueryResult<BOProductQuery, BOProductQueryVariables>;
export const SetBOMediaInfoDocument = gql`
    mutation SetBOMediaInfo($productId: String!, $key: String!, $title: String, $author: String, $editor: String, $interpreter: String, $downloadable: Boolean) {
  setMediaInfo(
    productId: $productId
    mediaKey: $key
    title: $title
    author: $author
    editor: $editor
    interpreter: $interpreter
    downloadable: $downloadable
  )
}
    `;
export type SetBOMediaInfoMutationFn = Apollo.MutationFunction<SetBOMediaInfoMutation, SetBOMediaInfoMutationVariables>;

/**
 * __useSetBOMediaInfoMutation__
 *
 * To run a mutation, you first call `useSetBOMediaInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBOMediaInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBoMediaInfoMutation, { data, loading, error }] = useSetBOMediaInfoMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      key: // value for 'key'
 *      title: // value for 'title'
 *      author: // value for 'author'
 *      editor: // value for 'editor'
 *      interpreter: // value for 'interpreter'
 *      downloadable: // value for 'downloadable'
 *   },
 * });
 */
export function useSetBOMediaInfoMutation(baseOptions?: Apollo.MutationHookOptions<SetBOMediaInfoMutation, SetBOMediaInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBOMediaInfoMutation, SetBOMediaInfoMutationVariables>(SetBOMediaInfoDocument, options);
      }
export type SetBOMediaInfoMutationHookResult = ReturnType<typeof useSetBOMediaInfoMutation>;
export type SetBOMediaInfoMutationResult = Apollo.MutationResult<SetBOMediaInfoMutation>;
export type SetBOMediaInfoMutationOptions = Apollo.BaseMutationOptions<SetBOMediaInfoMutation, SetBOMediaInfoMutationVariables>;
export const BOInternalReportDocument = gql`
    query BOInternalReport($from: DateTime!, $to: DateTime) {
  report(from: $from, to: $to) {
    activeUsers
    activatedProducts
    mediaReproductions
    mediaDownloads
  }
}
    `;

/**
 * __useBOInternalReportQuery__
 *
 * To run a query within a React component, call `useBOInternalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOInternalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOInternalReportQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useBOInternalReportQuery(baseOptions: Apollo.QueryHookOptions<BOInternalReportQuery, BOInternalReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOInternalReportQuery, BOInternalReportQueryVariables>(BOInternalReportDocument, options);
      }
export function useBOInternalReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOInternalReportQuery, BOInternalReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOInternalReportQuery, BOInternalReportQueryVariables>(BOInternalReportDocument, options);
        }
export type BOInternalReportQueryHookResult = ReturnType<typeof useBOInternalReportQuery>;
export type BOInternalReportLazyQueryHookResult = ReturnType<typeof useBOInternalReportLazyQuery>;
export type BOInternalReportQueryResult = Apollo.QueryResult<BOInternalReportQuery, BOInternalReportQueryVariables>;
export const BOSIAEReportDocument = gql`
    query BOSIAEReport($from: DateTime!, $to: DateTime!) {
  siaeReport(from: $from, to: $to) {
    preSignedURL
  }
}
    `;

/**
 * __useBOSIAEReportQuery__
 *
 * To run a query within a React component, call `useBOSIAEReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOSIAEReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOSIAEReportQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useBOSIAEReportQuery(baseOptions: Apollo.QueryHookOptions<BOSIAEReportQuery, BOSIAEReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOSIAEReportQuery, BOSIAEReportQueryVariables>(BOSIAEReportDocument, options);
      }
export function useBOSIAEReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOSIAEReportQuery, BOSIAEReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOSIAEReportQuery, BOSIAEReportQueryVariables>(BOSIAEReportDocument, options);
        }
export type BOSIAEReportQueryHookResult = ReturnType<typeof useBOSIAEReportQuery>;
export type BOSIAEReportLazyQueryHookResult = ReturnType<typeof useBOSIAEReportLazyQuery>;
export type BOSIAEReportQueryResult = Apollo.QueryResult<BOSIAEReportQuery, BOSIAEReportQueryVariables>;
export const BOUsersDocument = gql`
    query BOUsers($searchKey: String!, $page: Int!, $pageSize: Int!) {
  users(filter: {search: $searchKey}, page: $page, perPage: $pageSize) {
    resultCount
    users {
      ...BOUser
    }
  }
}
    ${BOUserFragmentDoc}`;

/**
 * __useBOUsersQuery__
 *
 * To run a query within a React component, call `useBOUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOUsersQuery({
 *   variables: {
 *      searchKey: // value for 'searchKey'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useBOUsersQuery(baseOptions: Apollo.QueryHookOptions<BOUsersQuery, BOUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOUsersQuery, BOUsersQueryVariables>(BOUsersDocument, options);
      }
export function useBOUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOUsersQuery, BOUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOUsersQuery, BOUsersQueryVariables>(BOUsersDocument, options);
        }
export type BOUsersQueryHookResult = ReturnType<typeof useBOUsersQuery>;
export type BOUsersLazyQueryHookResult = ReturnType<typeof useBOUsersLazyQuery>;
export type BOUsersQueryResult = Apollo.QueryResult<BOUsersQuery, BOUsersQueryVariables>;
export const SetBOUserDeviceLimitationsDocument = gql`
    mutation SetBOUserDeviceLimitations($userId: ID!, $unlimitedDeviceActivations: Boolean!, $unlimitedActiveDevices: Boolean!) {
  setDeviceLimitations(
    userId: $userId
    unlimitedDeviceActivations: $unlimitedDeviceActivations
    unlimitedActiveDevices: $unlimitedActiveDevices
  )
}
    `;
export type SetBOUserDeviceLimitationsMutationFn = Apollo.MutationFunction<SetBOUserDeviceLimitationsMutation, SetBOUserDeviceLimitationsMutationVariables>;

/**
 * __useSetBOUserDeviceLimitationsMutation__
 *
 * To run a mutation, you first call `useSetBOUserDeviceLimitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBOUserDeviceLimitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBoUserDeviceLimitationsMutation, { data, loading, error }] = useSetBOUserDeviceLimitationsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      unlimitedDeviceActivations: // value for 'unlimitedDeviceActivations'
 *      unlimitedActiveDevices: // value for 'unlimitedActiveDevices'
 *   },
 * });
 */
export function useSetBOUserDeviceLimitationsMutation(baseOptions?: Apollo.MutationHookOptions<SetBOUserDeviceLimitationsMutation, SetBOUserDeviceLimitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBOUserDeviceLimitationsMutation, SetBOUserDeviceLimitationsMutationVariables>(SetBOUserDeviceLimitationsDocument, options);
      }
export type SetBOUserDeviceLimitationsMutationHookResult = ReturnType<typeof useSetBOUserDeviceLimitationsMutation>;
export type SetBOUserDeviceLimitationsMutationResult = Apollo.MutationResult<SetBOUserDeviceLimitationsMutation>;
export type SetBOUserDeviceLimitationsMutationOptions = Apollo.BaseMutationOptions<SetBOUserDeviceLimitationsMutation, SetBOUserDeviceLimitationsMutationVariables>;
export const ResetBOUserDeviceActivationsDocument = gql`
    mutation ResetBOUserDeviceActivations($userId: ID!) {
  resetDevicesActivations(userId: $userId)
}
    `;
export type ResetBOUserDeviceActivationsMutationFn = Apollo.MutationFunction<ResetBOUserDeviceActivationsMutation, ResetBOUserDeviceActivationsMutationVariables>;

/**
 * __useResetBOUserDeviceActivationsMutation__
 *
 * To run a mutation, you first call `useResetBOUserDeviceActivationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBOUserDeviceActivationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBoUserDeviceActivationsMutation, { data, loading, error }] = useResetBOUserDeviceActivationsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResetBOUserDeviceActivationsMutation(baseOptions?: Apollo.MutationHookOptions<ResetBOUserDeviceActivationsMutation, ResetBOUserDeviceActivationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBOUserDeviceActivationsMutation, ResetBOUserDeviceActivationsMutationVariables>(ResetBOUserDeviceActivationsDocument, options);
      }
export type ResetBOUserDeviceActivationsMutationHookResult = ReturnType<typeof useResetBOUserDeviceActivationsMutation>;
export type ResetBOUserDeviceActivationsMutationResult = Apollo.MutationResult<ResetBOUserDeviceActivationsMutation>;
export type ResetBOUserDeviceActivationsMutationOptions = Apollo.BaseMutationOptions<ResetBOUserDeviceActivationsMutation, ResetBOUserDeviceActivationsMutationVariables>;
export const BOUserDocument = gql`
    query BOUser($id: ID!) {
  user(id: $id) {
    id
    email
    first_name
    last_name
  }
}
    `;

/**
 * __useBOUserQuery__
 *
 * To run a query within a React component, call `useBOUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBOUserQuery(baseOptions: Apollo.QueryHookOptions<BOUserQuery, BOUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOUserQuery, BOUserQueryVariables>(BOUserDocument, options);
      }
export function useBOUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOUserQuery, BOUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOUserQuery, BOUserQueryVariables>(BOUserDocument, options);
        }
export type BOUserQueryHookResult = ReturnType<typeof useBOUserQuery>;
export type BOUserLazyQueryHookResult = ReturnType<typeof useBOUserLazyQuery>;
export type BOUserQueryResult = Apollo.QueryResult<BOUserQuery, BOUserQueryVariables>;
export const BOUserProductsDocument = gql`
    query BOUserProducts($userId: ID!) {
  productInstances(userId: $userId) {
    product {
      ...BOProduct
    }
  }
}
    ${BOProductFragmentDoc}`;

/**
 * __useBOUserProductsQuery__
 *
 * To run a query within a React component, call `useBOUserProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBOUserProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBOUserProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBOUserProductsQuery(baseOptions: Apollo.QueryHookOptions<BOUserProductsQuery, BOUserProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BOUserProductsQuery, BOUserProductsQueryVariables>(BOUserProductsDocument, options);
      }
export function useBOUserProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BOUserProductsQuery, BOUserProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BOUserProductsQuery, BOUserProductsQueryVariables>(BOUserProductsDocument, options);
        }
export type BOUserProductsQueryHookResult = ReturnType<typeof useBOUserProductsQuery>;
export type BOUserProductsLazyQueryHookResult = ReturnType<typeof useBOUserProductsLazyQuery>;
export type BOUserProductsQueryResult = Apollo.QueryResult<BOUserProductsQuery, BOUserProductsQueryVariables>;
export const RemoveBOUserProductDocument = gql`
    mutation RemoveBOUserProduct($userId: ID!, $productId: ID!) {
  deleteUserProductInstance(userId: $userId, productId: $productId)
}
    `;
export type RemoveBOUserProductMutationFn = Apollo.MutationFunction<RemoveBOUserProductMutation, RemoveBOUserProductMutationVariables>;

/**
 * __useRemoveBOUserProductMutation__
 *
 * To run a mutation, you first call `useRemoveBOUserProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBOUserProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBoUserProductMutation, { data, loading, error }] = useRemoveBOUserProductMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveBOUserProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBOUserProductMutation, RemoveBOUserProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBOUserProductMutation, RemoveBOUserProductMutationVariables>(RemoveBOUserProductDocument, options);
      }
export type RemoveBOUserProductMutationHookResult = ReturnType<typeof useRemoveBOUserProductMutation>;
export type RemoveBOUserProductMutationResult = Apollo.MutationResult<RemoveBOUserProductMutation>;
export type RemoveBOUserProductMutationOptions = Apollo.BaseMutationOptions<RemoveBOUserProductMutation, RemoveBOUserProductMutationVariables>;
export const AddBOUserProductDocument = gql`
    mutation AddBOUserProduct($userId: ID!, $productId: ID!) {
  addUserProductInstance(userId: $userId, productId: $productId)
}
    `;
export type AddBOUserProductMutationFn = Apollo.MutationFunction<AddBOUserProductMutation, AddBOUserProductMutationVariables>;

/**
 * __useAddBOUserProductMutation__
 *
 * To run a mutation, you first call `useAddBOUserProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBOUserProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBoUserProductMutation, { data, loading, error }] = useAddBOUserProductMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useAddBOUserProductMutation(baseOptions?: Apollo.MutationHookOptions<AddBOUserProductMutation, AddBOUserProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBOUserProductMutation, AddBOUserProductMutationVariables>(AddBOUserProductDocument, options);
      }
export type AddBOUserProductMutationHookResult = ReturnType<typeof useAddBOUserProductMutation>;
export type AddBOUserProductMutationResult = Apollo.MutationResult<AddBOUserProductMutation>;
export type AddBOUserProductMutationOptions = Apollo.BaseMutationOptions<AddBOUserProductMutation, AddBOUserProductMutationVariables>;
export type DbAlignProblemKeySpecifier = ('productId' | 'dbMissingKeys' | 's3MissingKeys' | 'alreadyConvertedVideo' | 'notConvertedVideo' | 'convertedMediaToDelete' | DbAlignProblemKeySpecifier)[];
export type DbAlignProblemFieldPolicy = {
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	dbMissingKeys?: FieldPolicy<any> | FieldReadFunction<any>,
	s3MissingKeys?: FieldPolicy<any> | FieldReadFunction<any>,
	alreadyConvertedVideo?: FieldPolicy<any> | FieldReadFunction<any>,
	notConvertedVideo?: FieldPolicy<any> | FieldReadFunction<any>,
	convertedMediaToDelete?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeviceKeySpecifier = ('id' | 'deviceId' | 'userId' | 'label' | 'enabled' | 'creationDate' | 'lastSeenDate' | 'lastSeenAddress' | 'live' | 'type' | 'vendor' | 'model' | 'osName' | 'osVersion' | DeviceKeySpecifier)[];
export type DeviceFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	deviceId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	lastSeenDate?: FieldPolicy<any> | FieldReadFunction<any>,
	lastSeenAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	live?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vendor?: FieldPolicy<any> | FieldReadFunction<any>,
	model?: FieldPolicy<any> | FieldReadFunction<any>,
	osName?: FieldPolicy<any> | FieldReadFunction<any>,
	osVersion?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeviceEnablingHistoryKeySpecifier = ('id' | 'deviceId' | 'userId' | 'date' | DeviceEnablingHistoryKeySpecifier)[];
export type DeviceEnablingHistoryFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	deviceId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DownloadRequestKeySpecifier = ('key' | 'preSignedURL' | DownloadRequestKeySpecifier)[];
export type DownloadRequestFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	preSignedURL?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MediaKeySpecifier = ('key' | 'type' | 'description' | 'converted' | 'downloadable' | 'title' | 'author' | 'editor' | 'interpreter' | MediaKeySpecifier)[];
export type MediaFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	converted?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadable?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	author?: FieldPolicy<any> | FieldReadFunction<any>,
	editor?: FieldPolicy<any> | FieldReadFunction<any>,
	interpreter?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MediaUsageKeySpecifier = ('id' | 'productId' | 'userId' | 'mediaKey' | 'type' | 'creationDate' | MediaUsageKeySpecifier)[];
export type MediaUsageFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaKey?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('register' | 'bindProductInstanceToUser' | 'mediaDownloadPresignedURL' | 'setMediaUsage' | 'userAvatarUploadPresignedURL' | 'updateUserAvatar' | 'updateDevice' | 'removeDevice' | 'editDevice' | 'alignDatabaseWithS3' | 'convertMedias' | 'setAdminStatus' | 'setDeviceLimitations' | 'resetDevicesActivations' | 'clearDevices' | 'setMediaInfo' | 'deleteUserProductInstance' | 'addUserProductInstance' | 'createPlaylist' | 'deletePlaylist' | 'updatePlaylist' | 'addPlaylistElement' | 'removePlaylistElement' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	register?: FieldPolicy<any> | FieldReadFunction<any>,
	bindProductInstanceToUser?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaDownloadPresignedURL?: FieldPolicy<any> | FieldReadFunction<any>,
	setMediaUsage?: FieldPolicy<any> | FieldReadFunction<any>,
	userAvatarUploadPresignedURL?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserAvatar?: FieldPolicy<any> | FieldReadFunction<any>,
	updateDevice?: FieldPolicy<any> | FieldReadFunction<any>,
	removeDevice?: FieldPolicy<any> | FieldReadFunction<any>,
	editDevice?: FieldPolicy<any> | FieldReadFunction<any>,
	alignDatabaseWithS3?: FieldPolicy<any> | FieldReadFunction<any>,
	convertMedias?: FieldPolicy<any> | FieldReadFunction<any>,
	setAdminStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	setDeviceLimitations?: FieldPolicy<any> | FieldReadFunction<any>,
	resetDevicesActivations?: FieldPolicy<any> | FieldReadFunction<any>,
	clearDevices?: FieldPolicy<any> | FieldReadFunction<any>,
	setMediaInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUserProductInstance?: FieldPolicy<any> | FieldReadFunction<any>,
	addUserProductInstance?: FieldPolicy<any> | FieldReadFunction<any>,
	createPlaylist?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePlaylist?: FieldPolicy<any> | FieldReadFunction<any>,
	updatePlaylist?: FieldPolicy<any> | FieldReadFunction<any>,
	addPlaylistElement?: FieldPolicy<any> | FieldReadFunction<any>,
	removePlaylistElement?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PendingProductInstanceKeySpecifier = ('id' | 'userEmail' | 'productId' | PendingProductInstanceKeySpecifier)[];
export type PendingProductInstanceFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	userEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	productId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlaylistKeySpecifier = ('id' | 'name' | 'creationDate' | 'lastUpdateDate' | 'elements' | 'userId' | 'user' | 'live' | PlaylistKeySpecifier)[];
export type PlaylistFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	lastUpdateDate?: FieldPolicy<any> | FieldReadFunction<any>,
	elements?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	live?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlaylistElementKeySpecifier = ('productId' | 'product' | 'mediaKey' | 'media' | PlaylistElementKeySpecifier)[];
export type PlaylistElementFieldPolicy = {
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaKey?: FieldPolicy<any> | FieldReadFunction<any>,
	media?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductKeySpecifier = ('id' | 'media' | 'description' | 'img_url' | ProductKeySpecifier)[];
export type ProductFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	img_url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductInstanceKeySpecifier = ('id' | 'generated' | 'userId' | 'activationDate' | 'productId' | 'product' | 'mock' | ProductInstanceKeySpecifier)[];
export type ProductInstanceFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	generated?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	activationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	mock?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('login' | 'user' | 'refreshJwt' | 'productInstances' | 'devices' | 'nextDeviceEnablingDate' | 'nextDeviceEnablingDateIfEnablingOne' | 'dbAlignProblem' | 'users' | 'products' | 'report' | 'siaeReport' | 'playlists' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	login?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshJwt?: FieldPolicy<any> | FieldReadFunction<any>,
	productInstances?: FieldPolicy<any> | FieldReadFunction<any>,
	devices?: FieldPolicy<any> | FieldReadFunction<any>,
	nextDeviceEnablingDate?: FieldPolicy<any> | FieldReadFunction<any>,
	nextDeviceEnablingDateIfEnablingOne?: FieldPolicy<any> | FieldReadFunction<any>,
	dbAlignProblem?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	products?: FieldPolicy<any> | FieldReadFunction<any>,
	report?: FieldPolicy<any> | FieldReadFunction<any>,
	siaeReport?: FieldPolicy<any> | FieldReadFunction<any>,
	playlists?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReportKeySpecifier = ('id' | 'from' | 'to' | 'mediaDownloads' | 'mediaReproductions' | 'activeUsers' | 'activatedProducts' | ReportKeySpecifier)[];
export type ReportFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	from?: FieldPolicy<any> | FieldReadFunction<any>,
	to?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaDownloads?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaReproductions?: FieldPolicy<any> | FieldReadFunction<any>,
	activeUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	activatedProducts?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SIAEEntryKeySpecifier = ('productId' | 'mediaKey' | 'media' | 'title' | 'author' | 'editor' | 'interpreter' | 'reproductions' | 'downloads' | SIAEEntryKeySpecifier)[];
export type SIAEEntryFieldPolicy = {
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaKey?: FieldPolicy<any> | FieldReadFunction<any>,
	media?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	author?: FieldPolicy<any> | FieldReadFunction<any>,
	editor?: FieldPolicy<any> | FieldReadFunction<any>,
	interpreter?: FieldPolicy<any> | FieldReadFunction<any>,
	reproductions?: FieldPolicy<any> | FieldReadFunction<any>,
	downloads?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SIAEReportKeySpecifier = ('id' | 'retrieveId' | 'from' | 'to' | 'name' | 'entries' | SIAEReportKeySpecifier)[];
export type SIAEReportFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	retrieveId?: FieldPolicy<any> | FieldReadFunction<any>,
	from?: FieldPolicy<any> | FieldReadFunction<any>,
	to?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	entries?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UploadRequestKeySpecifier = ('key' | 'preSignedURL' | UploadRequestKeySpecifier)[];
export type UploadRequestFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	preSignedURL?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('id' | 'profilePicture' | 'admin' | 'unlimitedDeviceActivations' | 'unlimitedActiveDevices' | 'email' | 'first_name' | 'last_name' | 'username' | 'enabledDevicesInPeriodCount' | 'enabledDevicesCount' | 'productsCount' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	profilePicture?: FieldPolicy<any> | FieldReadFunction<any>,
	admin?: FieldPolicy<any> | FieldReadFunction<any>,
	unlimitedDeviceActivations?: FieldPolicy<any> | FieldReadFunction<any>,
	unlimitedActiveDevices?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	first_name?: FieldPolicy<any> | FieldReadFunction<any>,
	last_name?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	enabledDevicesInPeriodCount?: FieldPolicy<any> | FieldReadFunction<any>,
	enabledDevicesCount?: FieldPolicy<any> | FieldReadFunction<any>,
	productsCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UsersQueryResultKeySpecifier = ('users' | 'resultCount' | 'pageCount' | UsersQueryResultKeySpecifier)[];
export type UsersQueryResultFieldPolicy = {
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	resultCount?: FieldPolicy<any> | FieldReadFunction<any>,
	pageCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	DbAlignProblem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DbAlignProblemKeySpecifier | (() => undefined | DbAlignProblemKeySpecifier),
		fields?: DbAlignProblemFieldPolicy,
	},
	Device?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeviceKeySpecifier | (() => undefined | DeviceKeySpecifier),
		fields?: DeviceFieldPolicy,
	},
	DeviceEnablingHistory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeviceEnablingHistoryKeySpecifier | (() => undefined | DeviceEnablingHistoryKeySpecifier),
		fields?: DeviceEnablingHistoryFieldPolicy,
	},
	DownloadRequest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DownloadRequestKeySpecifier | (() => undefined | DownloadRequestKeySpecifier),
		fields?: DownloadRequestFieldPolicy,
	},
	Media?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MediaKeySpecifier | (() => undefined | MediaKeySpecifier),
		fields?: MediaFieldPolicy,
	},
	MediaUsage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MediaUsageKeySpecifier | (() => undefined | MediaUsageKeySpecifier),
		fields?: MediaUsageFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	PendingProductInstance?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PendingProductInstanceKeySpecifier | (() => undefined | PendingProductInstanceKeySpecifier),
		fields?: PendingProductInstanceFieldPolicy,
	},
	Playlist?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlaylistKeySpecifier | (() => undefined | PlaylistKeySpecifier),
		fields?: PlaylistFieldPolicy,
	},
	PlaylistElement?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlaylistElementKeySpecifier | (() => undefined | PlaylistElementKeySpecifier),
		fields?: PlaylistElementFieldPolicy,
	},
	Product?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductKeySpecifier | (() => undefined | ProductKeySpecifier),
		fields?: ProductFieldPolicy,
	},
	ProductInstance?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductInstanceKeySpecifier | (() => undefined | ProductInstanceKeySpecifier),
		fields?: ProductInstanceFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Report?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReportKeySpecifier | (() => undefined | ReportKeySpecifier),
		fields?: ReportFieldPolicy,
	},
	SIAEEntry?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SIAEEntryKeySpecifier | (() => undefined | SIAEEntryKeySpecifier),
		fields?: SIAEEntryFieldPolicy,
	},
	SIAEReport?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SIAEReportKeySpecifier | (() => undefined | SIAEReportKeySpecifier),
		fields?: SIAEReportFieldPolicy,
	},
	UploadRequest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UploadRequestKeySpecifier | (() => undefined | UploadRequestKeySpecifier),
		fields?: UploadRequestFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UsersQueryResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UsersQueryResultKeySpecifier | (() => undefined | UsersQueryResultKeySpecifier),
		fields?: UsersQueryResultFieldPolicy,
	}
};