import { Link } from 'react-router-dom';
import { Form, Input, Button, message, Col, Row } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { useRegisterMutation } from '../../apollo/api';
import { useAuth } from '../../auth';

type Values = { email: string; password: string; firstName: string; lastName: string };

export default function Register(p: { callback: () => void }) {
  const { setToken } = useAuth();

  const [register, { loading }] = useRegisterMutation({
    onCompleted: (data) => {
      setToken(data.register);
      p.callback();
    },
    onError: (e) => message.error(e.message),
  });

  return (
    <Form<Values> onFinish={(variables) => register({ variables })} className="Form">
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Inserisci la tua e-mail!',
          },
          {
            type: 'email',
            message: "L'e-mail non è valida!",
          },
        ]}
      >
        <Input prefix={<MailOutlined />} type="email" placeholder="E-mail" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Inserisci una password!',
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Conferma la password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Le due password che hai inserito non corrispondono!'));
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Conferma password" />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Inserisci il tuo nome!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Nome" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Inserisci il tuo cognome!',
              },
            ]}
          >
            <Input placeholder="Cognome" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Iscriviti
        </Button>
      </Form.Item>

      <Form.Item>
        Hai già un account? <Link to="/login">Accedi</Link>.
      </Form.Item>
    </Form>
  );
}
