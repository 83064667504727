import { useState } from 'react';
import { Alert, Avatar, Button, Col, message, Row, Space, Upload } from 'antd';
import { EditFilled, /*LockOutlined,*/ MailOutlined, UserOutlined } from '@ant-design/icons';
import { useGetAvatarUploadURLMutation, UserDocument, useSetAvatarMutation, useUserQuery } from '../apollo/api';

export default function Account() {
  const { loading: userLoading, error: userError, data: userData } = useUserQuery();
  const user = userData?.user;

  const [getAvatarUploadURL] = useGetAvatarUploadURLMutation();
  const [setAvatar] = useSetAvatarMutation({ refetchQueries: [{ query: UserDocument }] });

  const [avatarLoading, setAvatarLoading] = useState(false);

  return (
    <>
      <h1>Account</h1>

      {userLoading && <p>Caricamento...</p>}

      {userError && <Alert message={userError.message} type="error" showIcon />}

      <div className="Content">
        {user && (
          <Row gutter={[0, 10]}>
            <Col span={24} sm={{ span: 12 }}>
              <Row>
                <Col span="24">
                  <Avatar
                    src={user.profilePicture}
                    icon={user.profilePicture ? undefined : <UserOutlined />}
                    className="Account-avatar"
                  />
                </Col>
              </Row>

              <Row>
                <Col span="24">
                  <Upload
                    accept="image/*"
                    showUploadList={false}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      try {
                        if (!(file instanceof File)) throw new Error('Failed to upload');
                        const { key, preSignedURL } =
                          (
                            await getAvatarUploadURL({
                              variables: { fileName: file.name },
                            })
                          ).data?.userAvatarUploadPresignedURL ?? {};
                        if (!key || !preSignedURL) {
                          throw new Error('Failed to upload');
                        }
                        await fetch(preSignedURL, {
                          method: 'PUT',
                          headers: { 'Content-Type': file.type },
                          body: file,
                        });
                        const success = (await setAvatar({ variables: { key } })).data?.updateUserAvatar;
                        if (!success) throw new Error('Failed to update');
                        onSuccess?.(success, new XMLHttpRequest());
                      } catch (e) {
                        onError?.(e instanceof Error ? e : new Error('Failed to upload'));
                      }
                    }}
                    onChange={({ file: { status, error } }) => {
                      setAvatarLoading(status === 'uploading');
                      if (error) message.error(error.message);
                    }}
                  >
                    <Button type="link" icon={<EditFilled />} loading={avatarLoading} className="Action">
                      Modifica foto
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <div className="Account-details">
                <Row>
                  <Space>
                    <UserOutlined />
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </Space>
                </Row>

                <Row>
                  <Space>
                    <MailOutlined />
                    {user.email}
                  </Space>
                </Row>

                {/*<Row>
                  <Space>
                    <LockOutlined />
                    Modifica password
                  </Space>
                </Row>*/}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
