import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import dateFormat from 'dateformat';
import { usePlaylistsQuery } from '../apollo/api';
import PlaylistRenamingModal from '../ui-components/PlaylistRenamingModal';
import usePlaylistDeletionModal from '../ui-components/PlaylistDeletionModal';

export default function Playlists() {
  const { loading, error, data } = usePlaylistsQuery();
  const playlists = data?.playlists.slice().sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const [renamingPlaylistId, setRenamingPlaylistId] = useState<string>();

  const showPlaylistDeletionModal = usePlaylistDeletionModal();

  return (
    <>
      <h1>Playlist</h1>
      <small className="Heading-align">Da questa pagina puoi riprodurre e gestire le playlist che hai creato.</small>

      <div className="Content">
        {loading && <p>Caricamento...</p>}

        {error && <Alert message={error.message} type="error" showIcon />}

        {playlists && (
          <Row gutter={[50, 30]}>
            {playlists.map((l) => (
              <Col span={24} lg={{ span: 12 }} key={l.id}>
                <Row gutter={10} align="middle">
                  <Col span={1} />
                  <Col span={17} className="Playlist-data">
                    <Link to={(location) => `${location.pathname}/${l.id}`}>
                      <div>
                        <mark>{l.name}</mark>
                        <div>
                          <small>
                            <strong>{`${l.elements.length} element${l.elements.length === 1 ? 'o' : 'i'}`}</strong>
                            <br />
                            {`Creazione: ${dateFormat(l.creationDate, 'dd/mm/yyyy HH:MM')}`}
                            <br />
                            {`Ultima modifica: ${dateFormat(l.lastUpdateDate, 'dd/mm/yyyy HH:MM')}`}
                          </small>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col span={6}>
                    <Button
                      type="link"
                      icon={<EditFilled />}
                      onClick={() => setRenamingPlaylistId(l.id)}
                      className="Action Action-button"
                    >
                      Rinomina
                    </Button>
                    <PlaylistRenamingModal
                      playlistId={l.id}
                      name={l.name ?? ''}
                      visible={l.id === renamingPlaylistId}
                      closeFn={() => setRenamingPlaylistId(undefined)}
                    />
                    <br />
                    <Button
                      type="link"
                      icon={<DeleteFilled />}
                      onClick={() => showPlaylistDeletionModal(l.id)}
                      className="Action Action-button"
                    >
                      Elimina
                    </Button>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </>
  );
}
