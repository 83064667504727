import { message, Modal } from 'antd';
import { useCallback } from 'react';
import { PlaylistsDocument, useDeletePlaylistMutation } from '../apollo/api';

export default function usePlaylistDeletionModal(p?: { onCompleted?: () => void }) {
  const [deletePlaylist] = useDeletePlaylistMutation({
    onCompleted: () => p?.onCompleted?.(),
    onError: (e) => message.error(e.message),
    refetchQueries: [{ query: PlaylistsDocument }],
  });

  const showModal = useCallback(
    (id: string) => {
      Modal.confirm({
        title: 'Elimina playlist',
        content: 'Eliminare questa playlist?',
        cancelText: 'Annulla',
        okText: 'Continua',
        onOk: () => deletePlaylist({ variables: { id } }),
      });
    },
    [deletePlaylist]
  );

  return showModal;
}
