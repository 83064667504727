import { Avatar } from 'antd';
import { MediaType } from '../apollo/api';
import pdf from '../images/pdf.png';
import epub from '../images/epub.png';
import audio from '../images/audio.png';
import video from '../images/video.png';

export default function MediaIcon(p: { type: MediaType; size: number }) {
  const icon =
    p.type === MediaType.PDF
      ? pdf
      : p.type === MediaType.EPUB
      ? epub
      : p.type === MediaType.AUDIO
      ? audio
      : p.type === MediaType.VIDEO
      ? video
      : undefined;

  const elementClass =
    p.type === MediaType.PDF
      ? 'Element-pdf'
      : p.type === MediaType.EPUB
      ? 'Element-epub'
      : p.type === MediaType.AUDIO
      ? 'Element-audio'
      : p.type === MediaType.VIDEO
      ? 'Element-video'
      : undefined;

  return <Avatar size={p.size} src={icon} className={`Media-icon ${elementClass}`} />;
}
