import { useMemo } from 'react';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import introspection from './api/possible-types';
import { useAuth } from '../auth';

export function useApolloClient(deviceId: string) {
  const { token, removeToken } = useAuth();

  return useMemo(() => {
    const link = new ApolloLink((operation, forward) => {
      const headers: { [key: string]: string } = { 'Accept-Version': '0.0.1' };
      headers['Device-ID'] = deviceId;
      if (token) headers['Authorization'] = `Bearer ${token}`;
      operation.setContext({ headers });
      return forward(operation);
    })
      .concat(
        onError((e) => {
          if (e.graphQLErrors?.some(({ extensions }) => extensions?.code === 'UNAUTHENTICATED')) {
            removeToken();
          }
        })
      )
      .concat(new HttpLink({ uri: 'https://app.progettisonori.it/api' })); //TODO: env variable?

    const cache = new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
    });

    return new ApolloClient({ link, cache });
  }, [deviceId, token, removeToken]);
}
