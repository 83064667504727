import { useState } from 'react';
import { Link, matchPath, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Alert, Avatar, Button, Col, Drawer, Layout, Menu, Row, Skeleton, Space } from 'antd';
import { StarFilled, UserOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { User, UserQuery, useUserQuery } from '../apollo/api';
import { useAuth } from '../auth';
import IconFont from '../ui-components/IconFont';
import Account from './Account';
import Entry from './Entry';
import Library from './Library';
import Product from './Product';
import Media from './Media';
import Playlists from './Playlists';
import Playlist from './Playlist';
import Devices from './Devices';
import BOUsers from './back-office/Users';
import BOUser from './back-office/User';
import BOProducts from './back-office/Products';
import BOProduct from './back-office/Product';
import BOReports from './back-office/Reports';

const LIBRARY_PATH = '/library';
const PLAYLISTS_PATH = '/playlists';
const ACCOUNT_PATH = '/account';
const DEVICES_PATH = '/devices';
const BO_USERS_PATH = '/bo/users';
const BO_PRODUCTS_PATH = '/bo/products';
const BO_REPORTS_PATH = '/bo/reports';
const MENU_WIDTH = 250;

enum MenuItem {
  Account,
  Devices,
  BOUsers,
  BOProducts,
  BOReports,
  Logout,
}

function userLabel(user: User) {
  return user?.first_name ?? user?.last_name ?? 'Utente';
}

export default function Pages() {
  const [drawer, setDrawer] = useState(false);

  const { loading: userLoading, error: userError, data: userData } = useUserQuery();
  const user = userData?.user;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Layout.Header className="Layout-header Hidden-ge-md">
          {userLoading && (
            <Space>
              <Skeleton title={false} paragraph={{ style: { margin: 'auto' }, width: 140, rows: 1 }} active />
              <Skeleton.Avatar size="large" active />
            </Space>
          )}

          {userError && <Alert message={userError.message} type="error" showIcon />}

          {user && (
            <Button type="link" onClick={() => setDrawer(true)} className="Action-button">
              <Space>
                <span>
                  Ciao <mark>{userLabel(user)}</mark>!
                </span>
                <Avatar
                  src={user.profilePicture}
                  icon={user.profilePicture ? undefined : <UserOutlined />}
                  size="large"
                />
              </Space>
            </Button>
          )}
        </Layout.Header>

        <Layout.Content className="Layout-content">
          <Switch>
            <Route path={`${LIBRARY_PATH}/:productId/:mediaIndex`}>
              <Media />
            </Route>
            <Route path={`${LIBRARY_PATH}/:productId`}>
              <Product />
            </Route>
            <Route path={LIBRARY_PATH}>
              <Library />
            </Route>
            <Route path={`${PLAYLISTS_PATH}/:playlistId/:elementIndex?`}>
              <Playlist />
            </Route>
            <Route path={PLAYLISTS_PATH}>
              <Playlists />
            </Route>
            <Route path={ACCOUNT_PATH}>
              <Account />
            </Route>
            <Route path={DEVICES_PATH}>
              <Devices />
            </Route>
            <Route path={`${BO_USERS_PATH}/:userId`}>{user && <BOUser authUserId={user.id} />}</Route>
            <Route path={BO_USERS_PATH}>
              <BOUsers />
            </Route>
            <Route path={`${BO_PRODUCTS_PATH}/:productId`}>
              <BOProduct />
            </Route>
            <Route path={BO_PRODUCTS_PATH}>
              <BOProducts />
            </Route>
            <Route path={BO_REPORTS_PATH}>
              <BOReports />
            </Route>
            <Route path="/">
              <Entry />
            </Route>
          </Switch>
        </Layout.Content>

        <Layout.Footer className="Layout-footer">
          <Row>
            <Col offset={3} span={6}>
              <Link to="/">
                <IconFont type="icon-home-fill" className="Footer-icon" />
              </Link>
            </Col>
            <Col span={6}>
              <Link to={LIBRARY_PATH}>
                <IconFont type="icon-Library-1" className="Footer-icon" />
              </Link>
            </Col>
            <Col span={6}>
              <Link to={PLAYLISTS_PATH}>
                <StarFilled className="Footer-icon" />
              </Link>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>

      <Layout.Sider className="Layout-sider Hidden-lt-md" width={MENU_WIDTH}>
        <SideMenu user={{ loading: userLoading, error: userError, data: userData }} onSelect={() => setDrawer(false)} />
      </Layout.Sider>

      <Drawer
        visible={drawer}
        closable={false}
        onClose={() => setDrawer(false)}
        getContainer={false}
        className="Hidden-ge-md"
        bodyStyle={{ padding: 0 }}
        width={MENU_WIDTH}
      >
        <SideMenu user={{ loading: userLoading, error: userError, data: userData }} onSelect={() => setDrawer(false)} />
      </Drawer>
    </Layout>
  );
}

function SideMenu(p: {
  user: { loading: boolean; error: ApolloError | undefined; data: UserQuery | undefined };
  onSelect?: () => void;
}) {
  const error = p.user.error;
  const user = p.user.data?.user;

  const path = useLocation().pathname;
  const history = useHistory();
  const { removeToken } = useAuth();

  const selected = matchPath(path, ACCOUNT_PATH)
    ? [MenuItem.Account.toString()]
    : matchPath(path, DEVICES_PATH)
    ? [MenuItem.Devices.toString()]
    : matchPath(path, BO_USERS_PATH)
    ? [MenuItem.BOUsers.toString()]
    : matchPath(path, BO_PRODUCTS_PATH)
    ? [MenuItem.BOProducts.toString()]
    : matchPath(path, BO_REPORTS_PATH)
    ? [MenuItem.BOReports.toString()]
    : undefined;

  return (
    <>
      <div className="Content">
        {p.user.loading && (
          <>
            <Skeleton.Avatar className="Account-avatar" active />
            <br />
            <br />
            <Skeleton title={false} paragraph={{ style: { margin: 'auto', width: 140 }, width: 140, rows: 1 }} active />
          </>
        )}

        {error && <Alert message={error.message} type="error" showIcon />}

        {user && (
          <>
            <Avatar
              src={user.profilePicture}
              icon={user.profilePicture ? undefined : <UserOutlined />}
              className="Account-avatar"
            />
            <br />
            <br />
            Ciao <mark>{userLabel(user)}</mark>!
          </>
        )}
      </div>

      <Menu
        className="Menu"
        mode="inline"
        selectedKeys={selected}
        onSelect={({ selectedKeys: [key] }) => {
          switch (Number(key)) {
            case MenuItem.Account:
              history.push(ACCOUNT_PATH);
              break;
            case MenuItem.Devices:
              history.push(DEVICES_PATH);
              break;
            case MenuItem.BOUsers:
              history.push(BO_USERS_PATH);
              break;
            case MenuItem.BOProducts:
              history.push(BO_PRODUCTS_PATH);
              break;
            case MenuItem.BOReports:
              history.push(BO_REPORTS_PATH);
              break;
            case MenuItem.Logout:
              removeToken();
              history.push('/');
              break;
          }
          p.onSelect?.();
        }}
      >
        <Menu.Item key={MenuItem.Account}>Account</Menu.Item>
        <Menu.Item key={MenuItem.Devices}>Dispositivi</Menu.Item>
        {user?.admin && (
          <Menu.SubMenu key={'BackOffice'} title="Amministrazione">
            <Menu.Item key={MenuItem.BOUsers}>Utenti</Menu.Item>
            <Menu.Item key={MenuItem.BOProducts}>Prodotti</Menu.Item>
            <Menu.Item key={MenuItem.BOReports}>Report</Menu.Item>
          </Menu.SubMenu>
        )}
        <Menu.Item key={MenuItem.Logout} danger>
          Esci
        </Menu.Item>
      </Menu>
    </>
  );
}
