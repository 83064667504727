import { createContext, useContext, useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const DeviceIdContext = createContext<string | undefined>(undefined);

export function DeviceIdProvider({ children }: any) {
  const [deviceId, setDeviceId] = useState<string>();

  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => setDeviceId(result.visitorId));
  }, []);

  return <DeviceIdContext.Provider value={deviceId}>{children}</DeviceIdContext.Provider>;
}

export function useDeviceId() {
  return useContext(DeviceIdContext);
}
