import { Link } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthTokenLazyQuery } from '../../apollo/api';
import { useAuth } from '../../auth';

type Values = { email: string; password: string };

export default function Login(p: { callback: () => void }) {
  const { setToken } = useAuth();

  const [fetchAuthToken, { loading }] = useAuthTokenLazyQuery({
    onCompleted: (data) => {
      setToken(data.login);
      p.callback();
    },
    onError: (e) => message.error(e.message),
  });

  return (
    <Form<Values> onFinish={(variables) => fetchAuthToken({ variables })}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Inserisci la tua e-mail!',
          },
          {
            type: 'email',
            message: "L'e-mail non è valida!",
          },
        ]}
        className="Form"
      >
        <Input prefix={<MailOutlined />} type="email" placeholder="E-mail" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Inserisci la tua password!',
          },
        ]}
        className="Form"
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Accedi
        </Button>
      </Form.Item>

      <Form.Item>
        Non hai un account? <Link to="/register">Iscriviti</Link>.
        <br />
        <small>
          Se sei già registrato sul sito <a href="https://www.progettisonori.it">progettisonori.it</a> puoi utilizzare
          le stesse credenziali.
        </small>
      </Form.Item>

      <Form.Item>
        <small>
          <a href="https://www.progettisonori.it/account/lost-password">Hai dimenticato la password?</a>
        </small>
      </Form.Item>
    </Form>
  );
}
