import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, message, Modal } from 'antd';
import { DeleteOutlined, DownloadOutlined, MenuOutlined, StarOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Media,
  useGetMediaDownloadURLMutation,
  useRemovePlaylistElementMutation,
  useTrackMediaDownloadMutation,
} from '../apollo/api';
import MediaIcon from './MediaIcon';
import PlaylistCreationModal from './PlaylistCreationModal';

export default function MediaListItem(p: {
  productId: string;
  media: Media;
  link?: string;
  playlistId?: string;
  sortingId?: string;
  dragging?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: p.sortingId ?? '' });

  return (
    <div
      ref={setNodeRef}
      className={`Media-list-${p.link ? '' : 'current-'}item`}
      style={
        p.dragging
          ? { visibility: 'hidden' }
          : {
              transform: CSS.Transform.toString(transform),
              transition,
            }
      }
      {...(p.dragging ? {} : attributes)}
    >
      <MediaListItemContent dndListeners={listeners} {...p} />
    </div>
  );
}

export function PresentationalMediaListItem(p: {
  productId: string;
  media: Media;
  link?: string;
  playlistId?: string;
  sortingId?: string;
  dragging?: boolean;
}) {
  return (
    <div
      className={`Media-list-${p.link ? '' : 'current-'}item`}
      style={{
        margin: 'initial',
        width: 'initial',
      }}
    >
      <MediaListItemContent {...p} />
    </div>
  );
}

function MediaListItemContent({
  productId,
  media,
  link,
  playlistId,
  sortingId,
  dragging = false,
  dndListeners,
}: {
  productId: string;
  media: Media;
  link?: string;
  playlistId?: string;
  sortingId?: string;
  dragging?: boolean;
  dndListeners?: ReturnType<typeof useSortable>['listeners'];
}) {
  const [trackMediaDownload] = useTrackMediaDownloadMutation();

  const [getMediaDownloadURL, { loading: mediaDownloadURLLoading }] = useGetMediaDownloadURLMutation({
    onCompleted: (data) => {
      trackMediaDownload({ variables: { productId, key: media.key } });
      window.open(data.mediaDownloadPresignedURL.preSignedURL, '_blank');
    },
    onError: (e) => message.error(e.message),
  });

  const [playlistCreationModalVisible, setPlaylistCreationModalVisible] = useState(false);

  const [removeFromPlaylist] = useRemovePlaylistElementMutation({
    onError: (e) => message.error(e.message),
  });

  const data = (
    <div className={`Media-list-item-data`}>
      <MediaIcon type={media.type} size={26} />
      <span dangerouslySetInnerHTML={{ __html: media.description ?? '' }} className={'Media-list-item-title'} />
    </div>
  );
  return (
    <>
      {link ? (
        <Link to={link} replace className="Media-list-item-link">
          {data}
        </Link>
      ) : (
        data
      )}
      {media.downloadable && (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          loading={mediaDownloadURLLoading}
          onClick={() => getMediaDownloadURL({ variables: { productId, key: media.key } })}
          className="Action-button"
        />
      )}
      <Button
        type="link"
        icon={<StarOutlined />}
        onClick={() => setPlaylistCreationModalVisible(true)}
        className="Action-button"
      />
      <PlaylistCreationModal
        productId={productId}
        mediaKey={media.key}
        ignoredPlaylistId={playlistId}
        visible={playlistCreationModalVisible}
        closeFn={() => setPlaylistCreationModalVisible(false)}
      />
      {playlistId && (
        <>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: 'Rimuovi da playlist',
                content: 'Rimuovere questo elemento dalla playlist?',
                cancelText: 'Annulla',
                okText: 'Continua',
                onOk: () =>
                  removeFromPlaylist({
                    variables: { playlistId, productId, mediaKey: media.key },
                  }),
              });
            }}
            className="Action-button"
          />
          <Button
            type="link"
            icon={<MenuOutlined />}
            className="Action-button"
            style={{ cursor: dragging ? 'grabbing' : 'grab' }}
            {...(sortingId ? dndListeners : {})}
          />
        </>
      )}
    </>
  );
}
