import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import itIT from 'antd/lib/locale/it_IT';
import useModal from 'antd/lib/modal/useModal';
import { ApolloProvider } from '@apollo/client';
import { DeviceIdProvider, useDeviceId } from './device-id';
import { useApolloClient } from './apollo/client';
import AuthRoute from './auth/AuthRoute';
import Onboarding from './pages/onboarding';
import Pages from './pages';
import './App.css';

export default function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <ConfigProvider locale={itIT}>
          <DeviceIdProvider>
            <MainLoading />
          </DeviceIdProvider>
        </ConfigProvider>
      </CookiesProvider>
    </div>
  );
}

function MainLoading() {
  const deviceId = useDeviceId();

  return deviceId ? (
    <Main deviceId={deviceId} />
  ) : (
    <div className="MainLoading">
      <Spin size="large" />
    </div>
  );
}

function Main(p: { deviceId: string }) {
  const apolloClient = useApolloClient(p.deviceId);

  const [modal, contextHolder] = useModal();

  useEffect(() => {
    const startupMessage = localStorage.getItem('startup-message');
    if (startupMessage !== 'true') {
      modal.info({
        title: 'Benvenuto!',
        content: (
          <>
            Benvenuto!
            <br />
            Tramite l'App di Progetti Sonori potrai riprodurre video, audio, PDF ed e-book precedentemente acquistati.
            <br />
            <br />
            Ti sarà consentito l'accesso da un massimo di 3 dispositivi, che potrai utilizzare contemporaneamente. Su
            questi 3 dispositivi hai accessi illimitati. Non devi eliminarli (dalla pagina di gestione) quando passi da
            uno all'altro, altrimenti all'accesso successivo il sistema registrerà il dispositivo come nuovo, creando
            una nuova attivazione. Dopo 5 attivazioni di dispositivo, la tua utenza verrà bloccata per 30 giorni.
            <br />
            Puoi gestire i tuoi dispositivi dalla sezione apposita del menù dell'App.
          </>
        ),
        okText: 'Ok, ho capito.',
      });
      localStorage.setItem('startup-message', 'true');
    }
  }, [modal]);

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Switch>
          <Route path="/(login|register)">
            <Onboarding />
          </Route>
          <AuthRoute>
            <Pages />
          </AuthRoute>
        </Switch>
      </BrowserRouter>
      {contextHolder}
    </ApolloProvider>
  );
}
