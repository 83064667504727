export const previewStyles = {
  container: {
    overflow: 'hidden',
    height: '100%',
  },
  readerArea: {
    position: 'relative',
    zIndex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    transition: 'all .3s ease',
  },
  containerExpanded: {
    transform: 'translateX(256px)',
  },
  titleArea: {
    position: 'absolute',
    top: 20,
    left: 50,
    right: 50,
    textAlign: 'center',
    color: '#999',
  },
  reader: {
    position: 'absolute',
    top: 10,
    left: 0,
    bottom: 20,
    right: 0,
    cusrsor: 'pointer',
  },
  swipeWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 200,
  },
  arrow: {
    display: 'none',
  },
  tocButton: {
    display: 'none',
  },
  tocButtonBar: {
    display: 'none',
  },
  loadingView: {
    position: 'absolute',
    top: '50%',
    left: '10%',
    right: '10%',
    color: '#ccc',
    textAlign: 'center',
    margintop: '-.5em',
  },
};
