import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Col, Row, Typography } from 'antd';
import { Media, MediaType, useProductQuery } from '../apollo/api';
import BackButton from '../ui-components/BackButton';
import Bookcase from '../ui-components/Bookcase';
import MediaIcon from '../ui-components/MediaIcon';

export default function Product() {
  const { productId } = useParams<{ productId: string }>();

  const { loading, error, data } = useProductQuery({ variables: { id: productId } });

  const productInstance = data?.productInstances[0];
  const { product } = productInstance ?? {};

  const media = product?.media.reduce(
    (media, m) => ({ ...media, [m.type]: [...(media[m.type] ?? []), m] }),
    {} as { [id: string]: Media[] }
  );
  for (const type in media) {
    media[type]?.sort((a, b) => a.description.localeCompare(b.description));
  }

  const pdfs = media?.[MediaType.PDF];
  const epubs = media?.[MediaType.EPUB];
  const audios = media?.[MediaType.AUDIO];
  const videos = media?.[MediaType.VIDEO];

  return (
    <>
      {loading && <p>Caricamento...</p>}

      {error && <Alert message={error.message} type="error" showIcon />}

      {product && (
        <>
          <div className="Header">
            <BackButton className="Action-back" />
            <h1 dangerouslySetInnerHTML={{ __html: product.description ?? '' }} />
          </div>

          <Bookcase>
            {pdfs && <Section type={MediaType.PDF} media={pdfs} allMedia={product.media} />}
            {epubs && <Section type={MediaType.EPUB} media={epubs} allMedia={product.media} />}
            {audios && <Section type={MediaType.AUDIO} media={audios} allMedia={product.media} />}
            {videos && <Section type={MediaType.VIDEO} media={videos} allMedia={product.media} />}
          </Bookcase>
        </>
      )}
    </>
  );
}

function Section(p: { type: MediaType; media?: Media[]; allMedia: Media[] }) {
  const rowRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <h2 className="Library-section-title">{p.type}</h2>

      <Row
        ref={rowRef}
        wrap={false}
        onWheel={(e) => {
          if (e.deltaY === 0) return;
          e.preventDefault();
          rowRef.current?.scrollTo({ left: rowRef.current.scrollLeft + e.deltaY });
        }}
        className="Library-media-row"
      >
        {p.media ? (
          p.media.map((m) => (
            <Link to={(location) => `${location.pathname}/${p.allMedia.indexOf(m)}`} key={m.key}>
              <Col className="Library-media-item">
                <MediaIcon type={p.type} size={60} />
                <Typography.Paragraph ellipsis={{ rows: 3 }} className="Library-item-title">
                  <span dangerouslySetInnerHTML={{ __html: m.description ?? '' }} />
                </Typography.Paragraph>
              </Col>
            </Link>
          ))
        ) : (
          <Col className="Library-media-item" style={{ background: 'none' }} />
        )}
      </Row>
    </>
  );
}
