import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert } from 'antd';
import path from 'path';
import { useProductQuery } from '../apollo/api';
import BackButton from '../ui-components/BackButton';
import MediaPlayer from '../ui-components/MediaPlayer';
import MediaListItem from '../ui-components/MediaListItem';

export default function Media() {
  const location = useLocation();
  const history = useHistory();
  const { productId, mediaIndex } = useParams<{ productId: string; mediaIndex: string }>();

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useProductQuery({ variables: { id: productId } });

  const productInstance = productData?.productInstances[0];
  const { product } = productInstance ?? {};
  const media = product?.media[Number(mediaIndex)];
  const type = media?.type;
  const allMedia = product?.media
    .filter((m) => m.type === type)
    .sort((a, b) => a.description.localeCompare(b.description));
  const index = allMedia?.findIndex((m) => m === media);

  const textClass = type ? `Text-${type.toLowerCase()}` : undefined;

  function pathTo(index: number) {
    return `${path.dirname(location.pathname)}/${product!.media.indexOf(allMedia![index]!)}`;
  }

  return (
    <div className="Media-back">
      {productLoading && <p>Caricamento...</p>}

      {productError && <Alert message={productError.message} type="error" showIcon />}

      {media && (
        <div className="Header">
          <BackButton className={`Action-back ${textClass}`} />
          <h1 className={`Center ${textClass}`}>{media.description}</h1>
        </div>
      )}

      {product && type && allMedia && index !== undefined && (
        <>
          <MediaPlayer
            product={product}
            media={media}
            textClassName={textClass}
            elementClassName={`Element-${type.toLowerCase()}`}
            onBackward={index <= 0 ? undefined : () => history.replace(pathTo(index - 1))}
            onForward={index >= allMedia.length - 1 ? undefined : () => history.replace(pathTo(index + 1))}
          />

          {allMedia?.map((m, i) => (
            <MediaListItem productId={productId} media={m} link={i === index ? undefined : pathTo(i)} key={m.key} />
          ))}
        </>
      )}
    </div>
  );
}
