import { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Button, message } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { DndContext, closestCenter, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import path from 'path';
import { PlaylistQuery, usePlaylistQuery, useSetPlaylistElementsMutation } from '../apollo/api';
import BackButton from '../ui-components/BackButton';
import PlaylistRenamingModal from '../ui-components/PlaylistRenamingModal';
import usePlaylistDeletionModal from '../ui-components/PlaylistDeletionModal';
import MediaPlayer from '../ui-components/MediaPlayer';
import MediaListItem, { PresentationalMediaListItem } from '../ui-components/MediaListItem';

export default function Playlist() {
  const location = useLocation();
  const history = useHistory();
  const { playlistId, elementIndex } = useParams<{ playlistId: string; elementIndex?: string }>();

  const [renamingModalVisible, setRenamingModalVisible] = useState(false);

  const showPlaylistDeletionModal = usePlaylistDeletionModal({
    onCompleted: () => history.goBack(),
  });

  const {
    loading: playlistLoading,
    error: playlistError,
    data: playlistData,
  } = usePlaylistQuery({ variables: { id: playlistId } });

  const playlist = playlistData?.playlists[0];

  const [elements, setElements] = useState<(PlaylistQuery['playlists'][0]['elements'][0] & { id: string })[]>();

  useEffect(() => {
    setElements(playlist?.elements.map((e) => ({ ...e, id: e.media!.key })));
  }, [playlist]);

  const index = elementIndex ? Number(elementIndex) : 0;
  const element = playlist?.elements?.[index];
  const product = element?.product;
  const media = element?.media;
  const type = media?.type;

  const textClass = type ? `Text-${type.toLowerCase()}` : undefined;

  function pathTo(index: number) {
    return `${elementIndex ? path.dirname(location.pathname) : location.pathname}/${index}`;
  }

  const [setPlaylistElements, { loading: playlistElementsUpdateLoading }] = useSetPlaylistElementsMutation({
    onError: (e) => {
      message.error(e.message);
      setElements(playlist!.elements!.map((e) => ({ ...e, id: e.media!.key }))); // restore playlist elements
    },
  });

  const dndSensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [draggingElementId, setDraggingElementId] = useState<string>();
  const draggingElementIndex = elements?.findIndex((e) => e.id === draggingElementId);
  const draggingElement = draggingElementIndex !== undefined ? elements?.[draggingElementIndex] : undefined;

  return index > 0 && elements && index >= elements.length ? (
    <Redirect to={pathTo(Math.max(elements.length - 1, 0))} />
  ) : (
    <>
      {playlistLoading && <p>Caricamento...</p>}

      {playlistError && <Alert message={playlistError.message} type="error" showIcon />}

      {playlist && elements && (
        <>
          <div className="Header">
            <BackButton className="Action-back" />

            <h1>{playlist.name}</h1>

            <Button
              type="link"
              icon={<EditFilled />}
              onClick={() => setRenamingModalVisible(true)}
              className="Action Action-button"
            >
              <span className="Hidden-lt-sm Hidden-ge-md-lt-lg">Rinomina</span>
            </Button>
            <PlaylistRenamingModal
              playlistId={playlistId}
              name={playlist.name ?? ''}
              visible={renamingModalVisible}
              closeFn={() => setRenamingModalVisible(false)}
            />

            <Button
              type="link"
              icon={<DeleteFilled />}
              onClick={() => showPlaylistDeletionModal(playlistId)}
              className="Action Action-button"
            >
              <span className="Hidden-lt-sm Hidden-ge-md-lt-lg">Elimina</span>
            </Button>
          </div>

          <div className="Media-back">
            {media && (
              <div className="Subheader">
                <h1 className={`Center ${textClass}`}>{media.description}</h1>
              </div>
            )}

            {product && type && (
              <>
                <MediaPlayer
                  product={product}
                  media={media}
                  textClassName={textClass}
                  elementClassName={`Element-${type.toLowerCase()}`}
                  onBackward={index <= 0 ? undefined : () => history.replace(pathTo(index - 1))}
                  onForward={index >= elements.length - 1 ? undefined : () => history.replace(pathTo(index + 1))}
                />

                <DndContext
                  sensors={dndSensors}
                  collisionDetection={closestCenter}
                  onDragStart={({ active }) => setDraggingElementId(active.id)}
                  onDragCancel={() => setDraggingElementId(undefined)}
                  onDragEnd={({ active, over }) => {
                    setDraggingElementId(undefined);
                    if (over && active.id !== over.id) {
                      const oldIndex = elements!.findIndex((e) => e.id === active.id);
                      const newIndex = elements!.findIndex((e) => e.id === over.id);
                      const updatedElements = arrayMove(elements!, oldIndex, newIndex);
                      setElements(updatedElements);
                      setPlaylistElements({
                        variables: {
                          playlistId,
                          elements: updatedElements.map((e) => ({ productId: e.product!.id, mediaKey: e.id })),
                        },
                      });
                    }
                  }}
                >
                  <SortableContext items={elements} strategy={verticalListSortingStrategy}>
                    {elements.map((e, i) => (
                      <MediaListItem
                        productId={e.product!.id}
                        media={e.media!}
                        link={i === index ? undefined : pathTo(i)}
                        playlistId={playlistId}
                        sortingId={playlistElementsUpdateLoading ? undefined : e.id}
                        dragging={e.id === draggingElementId}
                        key={e.id}
                      />
                    ))}
                  </SortableContext>
                  <DragOverlay>
                    {draggingElementId && draggingElementIndex !== undefined && draggingElement && (
                      <PresentationalMediaListItem
                        productId={draggingElement.product!.id}
                        media={draggingElement.media!}
                        link={draggingElementIndex === index ? undefined : pathTo(draggingElementIndex)}
                        playlistId={playlistId}
                        sortingId={playlistElementsUpdateLoading ? undefined : draggingElementId}
                        dragging
                      />
                    )}
                  </DragOverlay>
                </DndContext>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
