import { ChangeEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Col, Input, Row, Switch, Table, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import {
  BOMediaFragment,
  BOProductFragment,
  BOProductMediaFragment,
  BOProductMediaFragmentDoc,
  useBOProductQuery,
  useSetBOMediaInfoMutation,
} from '../../apollo/api';
import BackButton from '../../ui-components/BackButton';

export default function Product() {
  const { productId } = useParams<{ productId: string }>();

  const { loading, error, data } = useBOProductQuery({ variables: { id: productId } });
  const product = data?.products[0];

  const columns: ColumnType<BOMediaFragment>[] = [
    {
      title: 'Media',
      fixed: 'left',
      key: 'description',
      render: (_, m) => (
        <strong>
          <span dangerouslySetInnerHTML={{ __html: m.description ?? '' }} />
        </strong>
      ),
    },
    {
      title: 'Titolo',
      width: 200,
      key: 'title',
      render: (_, m) => <MediaInfo product={product!} media={m} field="title" />,
    },
    {
      title: 'Autore',
      width: 200,
      key: 'author',
      render: (_, m) => <MediaInfo product={product!} media={m} field="author" />,
    },
    {
      title: 'Editore',
      width: 200,
      key: 'editor',
      render: (_, m) => <MediaInfo product={product!} media={m} field="editor" />,
    },
    {
      title: 'Interprete',
      width: 200,
      key: 'interpreter',
      render: (_, m) => <MediaInfo product={product!} media={m} field="interpreter" />,
    },
    {
      title: 'Scaricabile',
      align: 'center',
      width: 115,
      key: 'downloadability',
      render: (_, m) => <MediaDownloadability product={product!} media={m} />,
    },
  ];

  return (
    <>
      {loading && <p>Caricamento...</p>}

      {error && <Alert message={error.message} type="error" showIcon />}

      {product && (
        <>
          <div className="Header">
            <BackButton className="Action-back" />
            <h1>
              {product.id}: <span dangerouslySetInnerHTML={{ __html: product.description ?? '' }} />
            </h1>
          </div>
          <div className="BackOffice">
            <div className="Content">
              <Row>
                <Col span={24}>
                  <Table<BOMediaFragment>
                    columns={columns}
                    dataSource={product.media}
                    rowKey={(m) => m.key}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function nonEmptyInput(event: ChangeEvent<HTMLInputElement>) {
  const input = event.target.value;
  return input.length > 0 ? input : null;
}

function MediaInfo({
  product,
  media,
  field,
}: {
  product: BOProductFragment & BOProductMediaFragment;
  media: BOMediaFragment;
  field: 'title' | 'author' | 'editor' | 'interpreter';
}) {
  const [value, setValue] = useState<string | null>(media[field] ?? null);

  const [setMediaInfo, { loading }] = useSetBOMediaInfoMutation({
    onError: (e) => {
      setValue(media[field] ?? null);
      message.error(e.message);
    },
  });

  const onValueCommit = useCallback(() => {
    if (value === media[field]) {
      return;
    }
    setMediaInfo({
      variables: {
        productId: product.id,
        key: media.key,
        [field]: value,
      },
      update(cache) {
        cache.writeFragment({
          id: cache.identify(product),
          fragment: BOProductMediaFragmentDoc,
          fragmentName: 'BOProductMedia',
          data: { media: product!.media.map((m) => (m.key !== media.key ? m : { ...m, [field]: value })) },
        });
      },
    });
  }, [product, media, field, value, setMediaInfo]);

  return (
    <Input
      value={value ?? undefined}
      onChange={loading ? undefined : (e) => setValue(nonEmptyInput(e))}
      onPressEnter={loading ? undefined : onValueCommit}
      onBlur={loading ? undefined : onValueCommit}
      suffix={loading ? <LoadingOutlined /> : <span />}
    ></Input>
  );
}

function MediaDownloadability({
  product,
  media,
}: {
  product: BOProductFragment & BOProductMediaFragment;
  media: BOMediaFragment;
}) {
  const downloadable = media.downloadable || false;

  const [setMediaInfo, { loading }] = useSetBOMediaInfoMutation({
    onError: (e) => message.error(e.message),
  });

  const onMediaDownloadableChange = useCallback(() => {
    setMediaInfo({
      variables: {
        productId: product.id,
        key: media.key,
        downloadable: !downloadable,
      },
      update(cache) {
        cache.writeFragment({
          id: cache.identify(product),
          fragment: BOProductMediaFragmentDoc,
          fragmentName: 'BOProductMedia',
          data: { media: product!.media.map((m) => (m.key !== media.key ? m : { ...m, downloadable: !downloadable })) },
        });
      },
    });
  }, [product, media, downloadable, setMediaInfo]);

  return <Switch checked={downloadable} loading={loading} onChange={onMediaDownloadableChange} />;
}
