import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ReactReader } from 'react-reader';
import { Alert, Button, Modal, Slider, Space } from 'antd';
import Icon, {
  CaretLeftOutlined,
  CaretRightOutlined,
  FullscreenOutlined,
  PauseOutlined,
  SettingFilled,
  SoundOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { ApolloError } from '@apollo/client';
import {
  MediaFragment,
  MediaType,
  ProductFragment,
  useGetMediaURLMutation,
  useTrackMediaPlaybackMutation,
} from '../apollo/api';
import { useAudioVolume, volumeChangeSupported } from '../audio';
import BookCover from '../ui-components/BookCover';
import { previewStyles as EPUBPreviewStyles } from './react-reader-styles/ReactReaderStyles';

//const PitchShift = require('soundbank-pitch-shift');

const TIME_SLIDER_MAX = 500;
const VOLUME_SLIDER_MAX = 50;
const PDF_MODAL_MARGINS = 16;
const PDF_MODAL_MAX_WIDTH = 800;
const DEFAULT_PDF_HEIGHT = 300;
const AUDIO_HEIGHT = 44;
const VIDEO_HEIGHT = 300 + AUDIO_HEIGHT;
const DEFAULT_SECTION = { start: 0, end: 1 };
const DEFAULT_SLIDING_SECTION = { start: 0, end: TIME_SLIDER_MAX };

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function isDeviceDisabled(error: ApolloError) {
  return error.graphQLErrors.some((e) => e.extensions?.['code'] === 'DEVICE_IS_DISABLED');
}

function formatTime(seconds: number) {
  const { floor } = Math;
  const h = floor(seconds / (60 * 60));
  const m = floor((seconds - h * (60 * 60)) / 60);
  const s = floor(seconds - m * 60 - h * 60 * 60);
  return h > 0 ? `${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}` : `${m}:${s < 10 ? '0' : ''}${s}`;
}

function SquareFilled() {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
      <rect width="100%" height="100%" rx={1} ry={1} />
    </svg>
  );
}

function PDF(p: {
  file: string;
  page: number;
  onDocumentLoadSuccess?: (pdf: { numPages: number }) => void;
  onPageLoadSuccess?: (page: { width: number; height: number }) => void;
  width?: number;
  height?: number;
}) {
  return (
    <Document file={p.file} loading="Caricamento..." onLoadSuccess={p.onDocumentLoadSuccess} className="Media-pdf">
      <Page
        pageNumber={p.page}
        renderAnnotationLayer={false}
        loading="Caricamento..."
        className="Media-pdf-page"
        width={p.width}
        height={p.height}
        onLoadSuccess={(page) => p.onPageLoadSuccess?.({ width: page.originalWidth, height: page.originalHeight })}
      />
    </Document>
  );
}

function EPUB(p: { file: string; page: number; preview: boolean }) {
  if (p.preview) {
    return (
      <ReactReader
        location={p.page}
        url={p.file}
        loadingView={'Caricamento...'}
        showToc={false}
        swipeable
        // @ts-ignore
        styles={EPUBPreviewStyles}
        epubOptions={{
          flow: 'scrolled',
          manager: 'continuous',
        }}
      />
    );
  } else {
    return (
      <ReactReader
        location={0}
        url={p.file}
        loadingView={'Caricamento...'}
        showToc={false}
        swipeable={false}
        epubOptions={{
          flow: 'scrolled',
          manager: 'continuous',
        }}
      />
    );
  }
}

export default function MediaPlayer(p: {
  product: ProductFragment;
  media: MediaFragment;
  textClassName?: string;
  elementClassName?: string;
  onBackward?: () => void;
  onForward?: () => void;
}) {
  const productId = p.product.id;
  const mediaKey = p.media.key;

  const history = useHistory();

  const [getMediaURL, { loading: mediaURLLoading, error: mediaURLError, data: mediaURLData }] = useGetMediaURLMutation({
    onError: (e) => {
      if (isDeviceDisabled(e)) {
        Modal.error({
          title: 'Questo dispositivo non è attivo',
          content: (
            <>
              Per accedere ai contenuti devi attivarlo tramite la pagina di gestione dei dispostivi associati al tuo
              account.
            </>
          ),
          okText: 'Ok',
          onOk: () => {
            history.push('/devices');
          },
        });
      }
    },
  });

  const { preSignedURL } = mediaURLData?.mediaDownloadPresignedURL ?? {};

  useEffect(() => {
    getMediaURL({
      variables: { productId, key: mediaKey },
    });
  }, [getMediaURL, productId, mediaKey]);

  const [fullPDF, setFullPDF] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<{ width: number; height: number }>();
  const handlePDFOpening = () => setFullPDF(true);

  const [fullEPUB, setFullEPUB] = useState(false);
  const handleEPUBOpening = () => setFullEPUB(true);

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const [duration, setDuration] = useState<number>();
  const [position, setPosition] = useState(0);
  const [seek, setSeek] = useState<number>();
  const [playing, setPlaying] = useState(false);
  const { volume, setVolume } = useAudioVolume(0.75);
  const [section, setSection] = useState(DEFAULT_SECTION);
  const [rate, setRate] = useState(1);
  /*const audioSourceRef = useRef<AudioNode>();
  const audioDestinationRef = useRef<AudioDestinationNode>();
  const pitchShiftRef = useRef<any>();*/
  const playerRef = useRef<ReactPlayer>();

  /*function setupPitchShift() {
    if (pitchShiftRef.current) return;
    const mediaElement = playerRef.current?.getInternalPlayer() as HTMLMediaElement | null | undefined;
    if (!mediaElement) return;
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const audioSource = audioContext.createMediaElementSource(mediaElement);
    audioSourceRef.current = audioSource;
    const audioDestination = audioContext.destination;
    audioDestinationRef.current = audioDestination;
    const pitchShift = PitchShift(audioContext);
    pitchShift.transpose = 0;
    pitchShiftRef.current = pitchShift;
    pitchShift.connect(audioDestination);
    audioSource.connect(audioDestination);
  }*/

  const setPlayerRef = useCallback((p: ReactPlayer | null) => {
    if (!p) return;
    playerRef.current = p;
    /*pitchShiftRef.current = undefined;

    const setupMediaElement = () => {
      const mediaElement = p.getInternalPlayer() as HTMLMediaElement | null;
      if (!mediaElement) return false;
      mediaElement.crossOrigin = 'anonymous';
      return true;
    };

    if (!setupMediaElement()) setTimeout(setupMediaElement);*/
  }, []);

  const resetPlaybackTo = useCallback((start: number) => {
    playerRef.current?.seekTo(start);
    setPosition(start);
  }, []);

  function resetPlayback() {
    resetPlaybackTo(section.start);
  }

  useEffect(() => {
    setPlaying(false);
    setSection(DEFAULT_SECTION);
    setRate(1);
    resetPlaybackTo(DEFAULT_SECTION.start);
  }, [productId, mediaKey, resetPlaybackTo]);

  const type = p.media.type;
  const pdfDefaultWidth = pageSize ? (pageSize.width * DEFAULT_PDF_HEIGHT) / pageSize.height : undefined;
  const pdfMaxWidth = windowWidth - (20 + 10) * 2;
  const pdfWidth = pdfDefaultWidth && pdfDefaultWidth > pdfMaxWidth ? pdfMaxWidth : undefined;
  const height =
    type === MediaType.PDF
      ? DEFAULT_PDF_HEIGHT + 10
      : type === MediaType.AUDIO
      ? AUDIO_HEIGHT
      : type === MediaType.VIDEO
      ? VIDEO_HEIGHT
      : undefined;

  const [settingsVisible, setSettingsVisible] = useState(false);

  useEffect(() => {
    if (type !== MediaType.AUDIO && type !== MediaType.VIDEO) {
      setSettingsVisible(false);
    }
  }, [type]);

  const [slidingSection, setSlidingSection] = useState(DEFAULT_SLIDING_SECTION);
  //const [slidingPitchShift, setSlidingPitchShift] = useState(0);

  useEffect(() => {
    setSlidingSection(DEFAULT_SLIDING_SECTION);
    //setSlidingPitchShift(0);
  }, [productId, mediaKey]);

  const [playedMedia, setPlayedMedia] = useState<Record<string, string[]>>({});

  const [trackMediaPlayback] = useTrackMediaPlaybackMutation();

  const trackMediaPlaybackIfFirst = useCallback(() => {
    if (!playedMedia[productId]?.includes(mediaKey)) {
      trackMediaPlayback({ variables: { productId, key: mediaKey } });
      const playedMediaPerProduct = playedMedia[productId];
      if (playedMediaPerProduct) {
        playedMediaPerProduct.push(mediaKey);
      } else {
        playedMedia[productId] = [mediaKey];
      }
      setPlayedMedia(playedMedia);
    }
  }, [playedMedia, productId, mediaKey, trackMediaPlayback]);

  useEffect(() => {
    if (preSignedURL && playing) {
      trackMediaPlaybackIfFirst();
    }
  }, [preSignedURL, playing, trackMediaPlaybackIfFirst]);

  useEffect(() => {
    if (preSignedURL && fullPDF) {
      trackMediaPlaybackIfFirst();
    }
  }, [preSignedURL, fullPDF, trackMediaPlaybackIfFirst]);

  useEffect(() => {
    if (preSignedURL && fullEPUB) {
      trackMediaPlaybackIfFirst();
    }
  }, [preSignedURL, fullEPUB, trackMediaPlaybackIfFirst]);

  const actionLabelClass = `Action-label${p.textClassName ? ` ${p.textClassName}` : ''}`;

  return (
    <>
      {type === MediaType.AUDIO && <BookCover url={p.product.img_url} className="Media-cover" />}

      <div className="Media-container" style={{ height }}>
        {mediaURLLoading && <p>Caricamento...</p>}

        {mediaURLError && !isDeviceDisabled(mediaURLError) && (
          <Alert message={mediaURLError.message} type="error" showIcon />
        )}

        {preSignedURL && (
          <>
            {type === MediaType.PDF && (
              <>
                <Button type="link" onClick={handlePDFOpening} className="Action-button Media-pdf-button">
                  <PDF
                    file={preSignedURL}
                    page={1}
                    onDocumentLoadSuccess={(pdf) => {
                      setPagesCount(pdf.numPages);
                      setPageNumber(1);
                    }}
                    onPageLoadSuccess={(page) => setPageSize(page)}
                    width={pdfWidth}
                    height={pdfWidth ? undefined : DEFAULT_PDF_HEIGHT}
                  />
                </Button>

                <Modal
                  visible={fullPDF}
                  onCancel={() => setFullPDF(false)}
                  footer={null}
                  wrapClassName="Media-pdf-modal-container"
                  className="Media-pdf-modal"
                  style={{ maxWidth: `calc(100vw - ${PDF_MODAL_MARGINS}px)` }}
                  width={PDF_MODAL_MAX_WIDTH}
                  bodyStyle={{ padding: '20px 20px 0', textAlign: 'center' }}
                >
                  <PDF
                    file={preSignedURL}
                    page={pageNumber}
                    width={Math.min(windowWidth - PDF_MODAL_MARGINS, PDF_MODAL_MAX_WIDTH) - 20 * 2}
                  />

                  <Space>
                    <Button
                      type="link"
                      icon={<CaretLeftOutlined className="Action-icon" />}
                      disabled={pageNumber <= 1}
                      onClick={() => setPageNumber((pageNumber) => pageNumber - 1)}
                      className="Action-button"
                    />
                    <span>
                      Pagina {pageNumber} di {pagesCount ?? '--'}
                    </span>
                    <Button
                      type="link"
                      icon={<CaretRightOutlined className="Action-icon" />}
                      disabled={pageNumber >= pagesCount}
                      onClick={() => setPageNumber((pageNumber) => pageNumber + 1)}
                      className="Action-button"
                    />
                  </Space>
                </Modal>
              </>
            )}
            {type === MediaType.EPUB && (
              <>
                <Button type="link" onClick={handleEPUBOpening} className="Action-button Media-epub-button">
                  <EPUB file={preSignedURL} page={0} preview />
                </Button>

                <Modal
                  visible={fullEPUB}
                  onCancel={() => setFullEPUB(false)}
                  footer={null}
                  wrapClassName="Media-epub-modal-container"
                  className="Media-epub-modal"
                  bodyStyle={{ height: `${windowHeight - 40}px` }}
                >
                  <EPUB file={preSignedURL} page={0} preview={false} />
                </Modal>
              </>
            )}
            {(type === MediaType.AUDIO || type === MediaType.VIDEO) && (
              <>
                <ReactPlayer
                  ref={setPlayerRef}
                  url={preSignedURL}
                  progressInterval={40}
                  volume={volume}
                  playbackRate={rate}
                  onDuration={(duration) => setDuration(duration)}
                  onPlay={() => setPlaying(true)}
                  onProgress={({ played }) => {
                    if (played >= section.end) {
                      setPlaying(false);
                      resetPlayback();
                    } else if (played < section.start) {
                      resetPlayback();
                    } else {
                      setPosition(played);
                    }
                  }}
                  onPause={() => setPlaying(false)}
                  onError={() => setPlaying(false)}
                  playing={playing}
                  playsinline
                  config={{ file: { hlsOptions: { maxBufferLength: 10, maxMaxBufferLength: 10 } } }}
                  width="100%"
                  height={`calc(100% - ${AUDIO_HEIGHT}px)`}
                />

                <div className="Media-time">
                  <span className={p.textClassName}>{formatTime((duration ?? 0) * position)}</span>
                  <span className={p.textClassName}>{formatTime(duration ?? 0)}</span>
                </div>

                <Slider
                  max={TIME_SLIDER_MAX}
                  tipFormatter={(value) => formatTime(value && duration ? (value / TIME_SLIDER_MAX) * duration : 0)}
                  value={(seek ?? position) * TIME_SLIDER_MAX}
                  onChange={(value) => setSeek(value / TIME_SLIDER_MAX)}
                  onAfterChange={(value) => {
                    setSeek(undefined);
                    playerRef.current?.seekTo(value / TIME_SLIDER_MAX);
                  }}
                  className={`Media-slider-${type.toLocaleLowerCase()}`}
                />
              </>
            )}
          </>
        )}
      </div>

      <div className={`Media-controls ${p.elementClassName}`}>
        {(type === MediaType.AUDIO || type === MediaType.VIDEO) && volumeChangeSupported() && (
          <div className="Media-controls-volume">
            <SoundOutlined className="Action-icon" />
            <Slider
              max={VOLUME_SLIDER_MAX}
              tooltipVisible={false}
              value={volume * VOLUME_SLIDER_MAX}
              onChange={(value) => setVolume(value / VOLUME_SLIDER_MAX)}
              className="Media-slider-volume"
            />
          </div>
        )}

        <Button
          type="link"
          icon={<StepBackwardOutlined className="Action-icon" style={{ fontSize: 32 }} />}
          disabled={!p.onBackward}
          onClick={() => p.onBackward?.()}
          className="Action-button"
        />

        {type === MediaType.PDF && (
          <Button type="link" onClick={handlePDFOpening} className="Action-button">
            Apri
          </Button>
        )}

        {type === MediaType.EPUB && (
          <Button type="link" onClick={handleEPUBOpening} className="Action-button">
            Apri
          </Button>
        )}

        {(type === MediaType.AUDIO || type === MediaType.VIDEO) && (
          <>
            <Button
              type="link"
              icon={
                playing ? (
                  <PauseOutlined className="Action-icon" style={{ fontSize: 28 }} />
                ) : (
                  <CaretRightOutlined className="Action-icon" style={{ fontSize: 32 }} />
                )
              }
              onClick={() => {
                //setupPitchShift();
                setPlaying(!playing);
              }}
              className="Action-button"
            />
            <Button
              type="link"
              icon={
                <Icon component={SquareFilled} className="Action-icon" style={{ fontSize: 22, lineHeight: '36px' }} />
              }
              onClick={() => {
                setPlaying(false);
                resetPlayback();
              }}
              className="Action-button"
            />
          </>
        )}

        <Button
          type="link"
          icon={<StepForwardOutlined className="Action-icon" style={{ fontSize: 32 }} />}
          disabled={!p.onForward}
          onClick={() => p.onForward?.()}
          className="Action-button"
        />

        {type === MediaType.VIDEO && (
          <Button
            type="link"
            icon={<FullscreenOutlined className="Action-icon" style={{ fontSize: 28 }} />}
            onClick={() => {
              const p = playerRef.current?.getInternalPlayer() as any;
              if (screenfull.isEnabled) {
                screenfull.request(p);
              } else if (p && p.webkitEnterFullscreen && p.webkitSupportsFullscreen) {
                p.webkitEnterFullscreen();
              }
            }}
            className="Action-button"
          />
        )}

        {(type === MediaType.AUDIO || type === MediaType.VIDEO) && (
          <Button
            type="link"
            icon={<SettingFilled rotate={settingsVisible ? -30 : 0} className="Action-icon" />}
            onClick={() => {
              //setupPitchShift();
              setSettingsVisible(!settingsVisible);
            }}
            className="Action-button"
          />
        )}
      </div>

      {settingsVisible && (
        <table className="Media-settings">
          <tr>
            <td className={actionLabelClass}>Sezione</td>
            <td className="Media-setting-slider-cell">
              <Slider
                range
                max={TIME_SLIDER_MAX}
                tipFormatter={(value) => {
                  const v = value ?? 0;
                  return (
                    <span className={actionLabelClass}>
                      {v > slidingSection.start ? 'Fine' : 'Inizio'}
                      <br />
                      {formatTime((duration ?? 0) * (v / TIME_SLIDER_MAX))}
                    </span>
                  );
                }}
                tooltipVisible
                getTooltipPopupContainer={(tooltip) => tooltip}
                value={[slidingSection.start, slidingSection.end]}
                onChange={([start, end]) => setSlidingSection({ start, end })}
                onAfterChange={([start, end]) => {
                  start = start / TIME_SLIDER_MAX;
                  end = end / TIME_SLIDER_MAX;
                  setSection({ start, end });
                  if (position >= end) {
                    setPlaying(false);
                    resetPlaybackTo(start);
                  } else if (position < start) {
                    resetPlaybackTo(start);
                  }
                }}
                className={`Media-setting-slider Media-slider-${type.toLocaleLowerCase()}`}
              />
            </td>
          </tr>
          <tr>
            <td className={actionLabelClass}>Velocità</td>
            <td className="Media-setting-slider-cell">
              <Slider
                min={-50}
                max={50}
                marks={{ 0: undefined }}
                included={false}
                tipFormatter={(value) => {
                  const v = value ?? 0;
                  return v === 0 ? undefined : (
                    <span className={actionLabelClass}>
                      {v > 0 ? '+' : ''}
                      {v}%
                    </span>
                  );
                }}
                tooltipVisible
                getTooltipPopupContainer={(tooltip) => tooltip}
                value={rate * 100 - 100}
                onChange={(value) => setRate((value + 100) / 100)}
                className={`Media-setting-slider Media-slider-${type.toLocaleLowerCase()}`}
              />
            </td>
          </tr>
          {/*<tr>
            <td className={actionLabelClass}>Toni</td>
            <td className="Media-setting-slider-cell">
              <Slider
                min={-2}
                max={2}
                marks={{ 0: undefined }}
                included={false}
                tipFormatter={(value) => {
                  const v = value ?? 0;
                  return v === 0 ? undefined : (
                    <span className={actionLabelClass}>
                      {v > 0 ? '+' : ''}
                      {v / 2}
                    </span>
                  );
                }}
                tooltipVisible
                getTooltipPopupContainer={(tooltip) => tooltip}
                value={slidingPitchShift}
                onChange={(value) => {
                  const pitchShift = pitchShiftRef.current;
                  if (!pitchShift) return;
                  if (slidingPitchShift === 0 && value !== 0) {
                    const audioSource = audioSourceRef.current!;
                    audioSource.disconnect();
                    audioSource.connect(pitchShift);
                  } else if (slidingPitchShift !== 0 && value === 0) {
                    const audioSource = audioSourceRef.current!;
                    audioSource.disconnect();
                    audioSource.connect(audioDestinationRef.current!);
                  }
                  setSlidingPitchShift(value);
                  pitchShiftRef.current.transpose = value > 0 ? (value > 1 ? value - 1 : 0.5) : value; // empirical correction
                }}
                className={`Media-setting-slider Media-slider-${type.toLocaleLowerCase()}`}
              />
            </td>
          </tr>*/}
        </table>
      )}
    </>
  );
}
