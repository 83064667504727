import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import Scanner from 'react-qr-barcode-scanner';
import { ProductsDocument, useBindProductInstanceMutation } from '../apollo/api';

type Values = { code: string };

export default function Entry() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<boolean>();
  const [form] = Form.useForm<Values>();
  const history = useHistory();

  loadingRef.current = loading;

  const [bindProductInstance] = useBindProductInstanceMutation({
    onCompleted: async (data) => {
      const productId = data.bindProductInstanceToUser?.productId;
      if (!productId) {
        setLoading(false);
        return;
      }
      form.setFieldsValue({ code: '' });
      await message.success('Prodotto aggiunto alla libreria!');
      history.push(`/library/${productId}`);
    },
    onError: (e) => {
      message.error(e.message);
      setLoading(false);
    },
    refetchQueries: [{ query: ProductsDocument }],
  });

  function submit(code: string) {
    if (!loadingRef.current) bindProductInstance({ variables: { code } });
    setLoading(true);
  }

  return (
    <>
      <h1>Aggiungi alla libreria</h1>

      <div className="Textured-back">
        <div className="Scanner">
          {error ? (
            <p className="Content">
              Per scansionare il Codice Univoco è necessario consentire l'utilizzo della fotocamera.
            </p>
          ) : (
            <Scanner
              onUpdate={(_, result) => {
                if (result) submit(result.getText());
              }}
              onError={() => setError(true)}
            />
          )}
        </div>

        <div className="Content">
          <p>
            Se hai acquistato un libro con allegati digitali, accedi alla tua LIBRERIA per vedere quali contenuti sono
            già disponibili.
            <br />
            In alternativa, scansiona o trascrivi il Codice Univoco per salvare nella tua LIBRERIA le tracce audio/video
            acquistate.
            <br />
            Il Codice Univoco (23 caratteri vari) si ottiene grattando l'etichetta adesiva all'interno del volume che
            hai acquistato.
          </p>

          <Form<Values> form={form} onFinish={(v) => submit(v.code)} className="Form">
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Inserisci il codice!',
                },
              ]}
            >
              <Input prefix={<BarcodeOutlined />} placeholder="Codice" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Invia
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
