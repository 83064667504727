import { Image } from 'antd';
import cover from '../images/cover.png';

export default function BookCover({
  url,
  ...rest
}: {
  url?: string | null;
  wrapperClassName?: string;
  className?: string;
}) {
  return (
    <Image src={url ?? ''} fallback={cover} alt={`Copertina${url ? '' : ' mancante'}`} preview={false} {...rest} />
  );
}
