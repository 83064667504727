import { useState } from 'react';
import { Alert, Button, Col, message, Modal, Row, Switch } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import dateFormat from 'dateformat';
import { ApolloError } from '@apollo/client';
import {
  DevicesDocument,
  DevicesQuery,
  useDeviceDisablingAlertLazyQuery,
  useDeviceEnablingAlertLazyQuery,
  useDevicesQuery,
  useRemoveDeviceMutation,
  useSetDeviceEnabledMutation,
} from '../apollo/api';
import { useDeviceId } from '../device-id';
import IconFont from '../ui-components/IconFont';

function deviceLabel(device: DevicesQuery['devices'][0]) {
  const model = [device.vendor, device.model].filter((s) => !!s).join(' ');
  return device.label ?? (model.length > 0 ? model : device.osName ?? 'Sconosciuto');
}

export default function Devices() {
  const deviceId = useDeviceId()!;

  const { loading: devicesLoading, error: devicesError, data: devicesData } = useDevicesQuery();
  const devices = devicesData?.devices
    .slice()
    .sort((a, b) =>
      a.deviceId === deviceId
        ? -1
        : b.deviceId === deviceId
        ? 1
        : a.lastSeenDate > b.lastSeenDate
        ? -1
        : a.lastSeenDate < b.lastSeenDate
        ? 1
        : 0
    );

  const [subjectDeviceId, setSubjectDeviceId] = useState<string>();
  const [removingSubjectDevice, setRemovingSubjectDevice] = useState(false);

  const clearSubjectDevice = (error?: ApolloError) => {
    setSubjectDeviceId(undefined);
    setRemovingSubjectDevice(false);
    if (error) message.error(error.message);
  };

  const mutationOptions = {
    onCompleted: () => clearSubjectDevice(),
    onError: clearSubjectDevice,
  };

  const [removeDevice] = useRemoveDeviceMutation({ ...mutationOptions, refetchQueries: [{ query: DevicesDocument }] });

  const [setDeviceEnabled] = useSetDeviceEnabledMutation(mutationOptions);

  const [fetchDeviceDisablingAlert] = useDeviceDisablingAlertLazyQuery({
    onCompleted: (data) => {
      if (!subjectDeviceId) return;

      const complete = () => {
        if (removingSubjectDevice) {
          removeDevice({ variables: { id: subjectDeviceId } });
        } else {
          setDeviceEnabled({ variables: { deviceId: subjectDeviceId, enabled: false } });
        }
      };

      if (data.nextDeviceEnablingDate) {
        Modal.confirm({
          title: 'Limite di attivazioni',
          content: (
            <>
              Anche se {removingSubjectDevice ? 'elimini' : 'disattivi'} questo dispositivo non potrai comunque
              attivarne altri prima delle {dateFormat(data.nextDeviceEnablingDate, 'HH:MM')} del{' '}
              {dateFormat(data.nextDeviceEnablingDate, 'dd/mm/yyyy')}.
            </>
          ),
          cancelText: 'Annulla',
          onCancel: () => clearSubjectDevice(),
          okText: 'Continua',
          onOk: complete,
        });
      } else {
        complete();
      }
    },
    onError: clearSubjectDevice,
    fetchPolicy: 'no-cache',
  });

  const [fetchDeviceEnablingAlert] = useDeviceEnablingAlertLazyQuery({
    onCompleted: (data) => {
      if (!subjectDeviceId) return;

      const complete = () => {
        setDeviceEnabled({ variables: { deviceId: subjectDeviceId, enabled: true } });
      };

      if (data.nextDeviceEnablingDateIfEnablingOne && !data.nextDeviceEnablingDate) {
        Modal.confirm({
          title: 'Limite di attivazioni',
          content: (
            <>
              Se attivi questo dispositivo poi non potrai attivarne altri prima delle{' '}
              {dateFormat(data.nextDeviceEnablingDateIfEnablingOne, 'HH:MM')} del{' '}
              {dateFormat(data.nextDeviceEnablingDateIfEnablingOne, 'dd/mm/yyyy')}.
            </>
          ),
          cancelText: 'Annulla',
          onCancel: () => clearSubjectDevice(),
          okText: 'Continua',
          onOk: complete,
        });
      } else {
        complete();
      }
    },
    onError: clearSubjectDevice,
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <h1>Dispositivi</h1>
      <small className="Heading-align">
        Da questa pagina puoi gestire i dispositivi associati al tuo account.
        <br />
        Puoi utilizzare un massimo di <strong>3 dispositivi</strong> attivi contemporaneamente. Su questi 3 dispositivi
        hai accessi illimitati. Non devi eliminarli quando passi da uno all'altro, altrimenti all'accesso successivo il
        sistema registrerà il dispositivo come nuovo, creando una nuova attivazione. Dopo <strong>5 attivazioni</strong>{' '}
        di dispositivo la tua utenza verrà bloccata per 30 giorni.
      </small>

      <div className="Content">
        {devicesLoading && <p>Caricamento...</p>}

        {devicesError && <Alert message={devicesError.message} type="error" showIcon />}

        {devices && (
          <Row gutter={[50, 30]}>
            {devices.map((d) => (
              <Col span={24} lg={{ span: 12 }} key={d.id}>
                <Row gutter={10} align="middle">
                  <Col span={5}>
                    <IconFont
                      type={
                        d.type === 'mobile'
                          ? d.vendor === 'Apple'
                            ? 'icon-iPhone'
                            : 'icon-phonesmartphone'
                          : d.type === 'tablet'
                          ? d.vendor === 'Apple'
                            ? 'icon-ipad'
                            : 'icon-tablet'
                          : 'icon-computer'
                      }
                      style={{ fontSize: 55 }}
                    ></IconFont>
                  </Col>

                  <Col span={15} className="Device-data">
                    <mark>{deviceLabel(d)}</mark>
                    <br />
                    <small>
                      {d.deviceId === deviceId ? (
                        <strong>Questo dispositivo</strong>
                      ) : (
                        `Ultimo utilizzo: ${dateFormat(d.lastSeenDate, 'dd/mm/yyyy HH:MM')}`
                      )}
                    </small>
                    <br />
                    <Button
                      type="link"
                      icon={<DeleteFilled />}
                      loading={d.deviceId === subjectDeviceId && removingSubjectDevice}
                      disabled={d.deviceId === deviceId}
                      onClick={() => {
                        if (subjectDeviceId) return;
                        setSubjectDeviceId(d.deviceId);
                        setRemovingSubjectDevice(true);
                        if (d.enabled) {
                          fetchDeviceDisablingAlert();
                        } else {
                          removeDevice({ variables: { id: d.deviceId } });
                        }
                      }}
                      className="Action Action-button"
                    >
                      Elimina
                    </Button>
                  </Col>

                  <Col span={4}>
                    <Switch
                      checked={d.enabled}
                      loading={d.deviceId === subjectDeviceId && !removingSubjectDevice}
                      onChange={() => {
                        if (subjectDeviceId) return;
                        setSubjectDeviceId(d.deviceId);
                        if (d.enabled) {
                          fetchDeviceDisablingAlert();
                        } else {
                          fetchDeviceEnablingAlert();
                        }
                      }}
                    />
                    <br />
                    <span className="Action-label">{d.enabled ? 'Disattiva' : 'Attiva'}</span>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </>
  );
}
