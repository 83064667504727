import { useCallback } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { useSetPlaylistNameMutation } from '../apollo/api';

type Values = { name: string };

export default function PlaylistRenamingModal(p: {
  playlistId: string;
  name: string;
  visible?: boolean;
  closeFn?: () => void;
}) {
  const [form] = Form.useForm<Values>();

  const setInputRef = useCallback((i: Input | null) => {
    if (i) {
      i.focus();
      i.select();
    }
  }, []);

  const [setPlaylistName, { loading }] = useSetPlaylistNameMutation({
    onCompleted: p.closeFn,
    onError: (e) => message.error(e.message),
  });

  return (
    <Modal
      title="Rinomina playlist"
      visible={p.visible}
      cancelText="Annulla"
      onCancel={p.closeFn}
      okText="Continua"
      okButtonProps={{ form: 'form', htmlType: 'submit', loading }}
      destroyOnClose
      width={270}
    >
      <Form<Values>
        id="form"
        form={form}
        preserve={false}
        onFinish={(v) => setPlaylistName({ variables: { playlistId: p.playlistId, ...v } })}
      >
        <Form.Item
          name="name"
          initialValue={p.name}
          rules={[
            {
              required: true,
              message: 'Inserisci il nome della playlist!',
            },
          ]}
          className="Last-form-item"
        >
          <Input ref={setInputRef} placeholder="Nome" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
