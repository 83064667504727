import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

export default function BackButton(p: { className?: string }) {
  const history = useHistory();

  return (
    <Button
      type="link"
      icon={<LeftOutlined className="Action-icon" />}
      onClick={() => history.goBack()}
      className={`Action-button${p.className ? ` ${p.className}` : ''}`}
    />
  );
}
