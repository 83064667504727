import { useRef } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import logo from '../../images/logo.png';

export default function Onboarding() {
  const location = useLocation();
  const history = useHistory();

  const from = useRef(
    (location.state as { from: { pathname: string } })?.from ?? {
      pathname: '/',
    }
  );

  const callback = () => history.replace(from.current);

  return (
    <div className="Onboarding-back Content">
      <header className="Content">
        <img src={logo} alt="Progetti Sonori" className="Logo" />
      </header>
      <Switch>
        <Route path="/login">
          <Login callback={callback} />
        </Route>
        <Route path="/register">
          <Register callback={callback} />
        </Route>
      </Switch>
    </div>
  );
}
