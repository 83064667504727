import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Row, Switch, Table, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnType } from 'antd/lib/table';
import {
  BOUserFragment,
  BOUserFragmentDoc,
  useBOUsersQuery,
  useResetBOUserDeviceActivationsMutation,
  useSetBOUserDeviceLimitationsMutation,
} from '../../apollo/api';

const PAGE_SIZE = 50;

export default function Users() {
  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { loading, data } = useBOUsersQuery({
    variables: {
      searchKey: searchKey,
      page,
      pageSize: PAGE_SIZE,
    },
    onError: (e) => message.error(e.message),
  });

  const columns: ColumnType<BOUserFragment>[] = [
    {
      title: 'Utente',
      key: 'info',
      render: (_, u) => (
        <>
          {(u.first_name || u.last_name) && (
            <div>
              <strong>
                {u.first_name} {u.last_name}
              </strong>
            </div>
          )}
          {u.email && (
            <div>
              <strong>{u.email}</strong>
            </div>
          )}
          <div>Dispositivi attivi: {u.enabledDevicesCount}</div>
          <div>Attivazioni utilizzate: {u.enabledDevicesInPeriodCount}</div>
          <div>Prodotti Acquistati: {u.productsCount}</div>
        </>
      ),
    },
    {
      title: 'Dispositivi Illimitati',
      align: 'center',
      width: 180,
      key: 'limitations',
      render: (_, u) => <DeviceLimitations user={u} />,
    },
    {
      title: 'Reset Attivazioni',
      align: 'center',
      width: 180,
      key: 'reset',
      render: (_, u) => <DeviceActivationsReset user={u} />,
    },
    {
      align: 'center',
      width: 120,
      key: 'products',
      render: (_, u) => <Link to={(location) => `${location.pathname}/${u.id}`}>Prodotti...</Link>,
    },
  ];

  return (
    <>
      <h1>Clienti</h1>

      <div className="BackOffice">
        <div className="Content">
          <Row>
            <Col xs={24} xl={12}>
              <span>Ricerca Cliente</span>
              <Search
                placeholder="Inserisci un nome, cognome o email"
                enterButton="Cerca"
                onSearch={setSearchKey}
                loading={loading}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Table<BOUserFragment>
                columns={columns}
                dataSource={data?.users.users}
                rowKey={(u) => u.id}
                loading={loading}
                pagination={{ onChange: setPage, pageSize: PAGE_SIZE, total: data?.users.resultCount }}
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

function DeviceLimitations({ user }: { user: BOUserFragment }) {
  const unlimited = user.unlimitedActiveDevices || false;

  const [setDeviceLimitations, { loading }] = useSetBOUserDeviceLimitationsMutation({
    onError: (e) => message.error(e.message),
  });

  const onDeviceLimitationsChange = useCallback(() => {
    setDeviceLimitations({
      variables: {
        userId: user.id,
        unlimitedActiveDevices: !unlimited,
        unlimitedDeviceActivations: !unlimited,
      },
      update(cache) {
        cache.writeFragment({
          id: cache.identify(user),
          fragment: BOUserFragmentDoc,
          data: { ...user, unlimitedActiveDevices: !unlimited, unlimitedDeviceActivations: !unlimited },
        });
      },
    });
  }, [user, unlimited, setDeviceLimitations]);

  return <Switch checked={unlimited} loading={loading} onChange={onDeviceLimitationsChange} />;
}

function DeviceActivationsReset({ user }: { user: BOUserFragment }) {
  const [resetDeviceActivations] = useResetBOUserDeviceActivationsMutation({
    onError: (e) => message.error(e.message),
  });

  const onResetDeviceActivationsClick = useCallback(() => {
    Modal.confirm({
      title: 'Reset attivazioni',
      content:
        'Confermi di voler resettare le attivazioni di questo utente e permettergli di attivare nuovi dispositivi questo mese?',
      okText: 'Confermo',
      cancelText: 'Annulla',
      onOk: async () => {
        try {
          await resetDeviceActivations({
            variables: { userId: user.id },
            update(cache) {
              cache.writeFragment({
                id: cache.identify(user),
                fragment: BOUserFragmentDoc,
                data: { ...user, enabledDevicesInPeriodCount: 0 },
              });
            },
          });
        } catch (e) {}
      },
    });
  }, [user, resetDeviceActivations]);

  return <Button onClick={onResetDeviceActivationsClick}>Reset</Button>;
}
