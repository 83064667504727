import { useCallback, useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, message, Modal, Row } from 'antd';
import { ApolloError } from '@apollo/client';
import {
  PlaylistsDocument,
  useAddPlaylistElementMutation,
  useCreatePlaylistMutation,
  usePlaylistsQuery,
  useRemovePlaylistElementMutation,
} from '../apollo/api';

const ADD_REMOVE_MESSAGE_KEY = 'AddRemoveMessage';

type Values = { name: string };

export default function PlaylistCreationModal({
  productId,
  mediaKey,
  ignoredPlaylistId,
  visible,
  closeFn,
}: {
  productId: string;
  mediaKey: string;
  ignoredPlaylistId?: string;
  visible?: boolean;
  closeFn?: () => void;
}) {
  const { loading: playlistsLoading, error: playlistsError, data: playlistsData } = usePlaylistsQuery();
  const playlists = playlistsData?.playlists
    .filter((p) => p.id !== ignoredPlaylistId)
    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const [creationVisible, setCreationVisible] = useState(false);
  const [form] = Form.useForm<Values>();

  const setInputRef = useCallback((i: Input | null) => {
    i?.focus();
  }, []);

  const resetAndClose = () => {
    setCreationVisible(false);
    closeFn?.();
  };

  const [subjectPlaylistId, setSubjectPlaylistId] = useState<string>();
  const [subjectPlaylistName, setSubjectPlaylistName] = useState<string>();
  const [addingToSubjectPlaylist, setAddingToSubjectPlaylist] = useState(false);

  const clearSubjectPlaylist = (error?: ApolloError) => {
    setSubjectPlaylistId(undefined);
    setSubjectPlaylistName(undefined);
    setAddingToSubjectPlaylist(false);
    if (error) message.error(error.message);
  };

  const [addToPlaylist] = useAddPlaylistElementMutation({
    onCompleted: () => {
      message.destroy(ADD_REMOVE_MESSAGE_KEY);
      message.success({ key: ADD_REMOVE_MESSAGE_KEY, content: `Salvato in ${subjectPlaylistName}` });
      clearSubjectPlaylist();
    },
    onError: clearSubjectPlaylist,
  });

  const [removeFromPlaylist] = useRemovePlaylistElementMutation({
    onCompleted: () => {
      message.destroy(ADD_REMOVE_MESSAGE_KEY);
      message.success({ key: ADD_REMOVE_MESSAGE_KEY, content: `Rimosso da ${subjectPlaylistName}` });
      clearSubjectPlaylist();
    },
    onError: clearSubjectPlaylist,
  });

  const [createPlaylist, { loading: playlistCreationLoading }] = useCreatePlaylistMutation({
    onCompleted: () => setCreationVisible(false),
    onError: (e) => message.error(e.message),
    refetchQueries: [{ query: PlaylistsDocument }],
    awaitRefetchQueries: true,
  });

  return (
    <Modal
      title="Salva in..."
      visible={visible}
      onCancel={resetAndClose}
      footer={[
        <Button type="primary" onClick={resetAndClose}>
          Ok
        </Button>,
      ]}
      destroyOnClose
      width={270}
    >
      {playlistsLoading && <p>Caricamento...</p>}

      {playlistsError && <Alert message={playlistsError.message} type="error" showIcon />}

      {playlists && playlists.length > 0 && (
        <Row gutter={[0, 10]}>
          {playlists.map((l) => {
            const subjectPlaylist = subjectPlaylistId === l.id;
            const inPlaylist = l.elements.some((e) => e.product!.id === productId && e.media!.key === mediaKey);
            return (
              <Col span={24} key={l.id}>
                <Checkbox
                  checked={subjectPlaylist ? addingToSubjectPlaylist : inPlaylist}
                  onChange={() => {
                    if (subjectPlaylistId) return;
                    setSubjectPlaylistId(l.id);
                    setSubjectPlaylistName(l.name ?? '');
                    const variables = { playlistId: l.id, productId, mediaKey };
                    if (inPlaylist) {
                      removeFromPlaylist({ variables });
                    } else {
                      setAddingToSubjectPlaylist(true);
                      addToPlaylist({ variables });
                    }
                  }}
                  key={l.id}
                >
                  {l.name}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      )}

      {(playlistsError || (playlists && playlists.length > 0)) && <br />}

      {creationVisible ? (
        <Form<Values>
          form={form}
          onFinish={(v) => createPlaylist({ variables: { ...v, elements: { productId, mediaKey } } })}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Inserisci il nome della playlist!',
              },
            ]}
            className="Last-form-item"
            style={{ flex: 'auto' }}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              <Input
                ref={setInputRef}
                placeholder="Nuova playlist"
                onChange={(e) => form.setFieldsValue({ name: e.target.value })}
              />
              <Button type="primary" htmlType="submit" loading={playlistCreationLoading}>
                Crea
              </Button>
            </Input.Group>
          </Form.Item>
        </Form>
      ) : (
        <Button type="link" onClick={() => setCreationVisible(true)} className="Action Action-button">
          Crea nuova playlist...
        </Button>
      )}
    </Modal>
  );
}
