import { Button, DatePicker, Form, message } from 'antd';
import { useBOInternalReportLazyQuery, useBOSIAEReportLazyQuery } from '../../apollo/api';

type InternalReportValues = { from: Date; to?: Date };
type SIAEReportValues = { from: Date; to: Date };

export default function Reports() {
  const [fetchInternalReport, { loading: internalReportLoading, data: internalReportData }] =
    useBOInternalReportLazyQuery({
      onError: (e) => message.error(e.message),
    });
  const internalReport = internalReportData?.report;

  const [fetchSIAEReport, { loading: siaeReportLoading }] = useBOSIAEReportLazyQuery({
    onCompleted: (data) => {
      window.open(data.siaeReport.preSignedURL, '_blank');
    },
    onError: (e) => message.error(e.message),
  });

  return (
    <>
      <h1>Report</h1>

      <div className="BackOffice">
        <div className="Content">
          <strong>Report interno</strong>

          <Form<InternalReportValues> onFinish={(variables) => fetchInternalReport({ variables })} layout="inline">
            <Form.Item
              name="from"
              rules={[
                {
                  required: true,
                  message: 'Inserisci la data di inizio periodo!',
                },
                {
                  type: 'date',
                  message: 'La data non è valida!',
                },
              ]}
            >
              <DatePicker placeholder="Data inizio" />
            </Form.Item>
            <Form.Item
              name="to"
              rules={[
                {
                  type: 'date',
                  message: 'La data non è valida!',
                },
              ]}
            >
              <DatePicker placeholder="Data fine" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={internalReportLoading}>
                Ok
              </Button>
            </Form.Item>
          </Form>

          {internalReport && (
            <div>
              <div>Utenti attivi: {internalReport.activeUsers}</div>
              <div>Prodotti attivati: {internalReport.activatedProducts}</div>
              <div>Riproduzioni di media: {internalReport.mediaReproductions}</div>
              <div>Scaricamenti di media: {internalReport.mediaDownloads}</div>
            </div>
          )}

          <br />

          <strong>Report SIAE</strong>

          <Form<SIAEReportValues> onFinish={(variables) => fetchSIAEReport({ variables })} layout="inline">
            <Form.Item
              name="from"
              rules={[
                {
                  required: true,
                  message: 'Inserisci la data di inizio periodo!',
                },
                {
                  type: 'date',
                  message: 'La data non è valida!',
                },
              ]}
            >
              <DatePicker placeholder="Data inizio" />
            </Form.Item>
            <Form.Item
              name="to"
              rules={[
                {
                  required: true,
                  message: 'Inserisci la data di fine periodo!',
                },
                {
                  type: 'date',
                  message: 'La data non è valida!',
                },
              ]}
            >
              <DatePicker placeholder="Data fine" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={siaeReportLoading}>
                Ok
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
